import * as TreeCommons from "./tree-commons";
import { UsersService } from "@/services"
import { UserTreeModel } from "@/models/UserTreeModel";
import { NODE_TYPES } from "@/common/node-types";

export default {
    namespaced: true,
    state: {
        ...TreeCommons.state,
        entities: []
    },
    actions: {
        async searchOperators ({ commit, dispatch }, { SearchTerm, OperatorId, ActionName }) {
            const params = { SearchTerm, SortBy: "FirstName" };
            const response = await UsersService.searchOperators(params);
            const operators = response.data.Entities.map(x => UserTreeModel.create(x, NODE_TYPES.OPERATOR));
            commit("TREE_REMOVE_ENTITY_SELECTION");
            dispatch("setOperators", operators);

            if (!OperatorId) {
                return;
            }

            const operatorEntity = operators.find(x => x.Id === OperatorId);
            if (operatorEntity) {
                commit("SET_LAST_ENTITY_SELECTION", { OperatorId });
                dispatch("actions/selectActionByNodeTypeAndName", { nodeType: NODE_TYPES.OPERATOR, actionName: ActionName }, { root: true });
                dispatch("cspActions/selectActionByNodeTypeAndName", { nodeType: NODE_TYPES.OPERATOR, actionName: ActionName }, { root: true });
            }
        },
        setOperators ({ commit }, operators) {
            commit("TREE_SET_ENTITIES", operators);
        },
        selectOperator ({ commit, dispatch }, operator) {
            dispatch("actions/selectFirstActionByNodeType", { nodeType: operator.NodeType }, { root: true });
            dispatch("cspActions/selectFirstActionByNodeType", { nodeType: operator.NodeType }, { root: true });
            commit("TREE_REMOVE_ENTITY_SELECTION");
            commit("TREE_SET_ENTITY_SELECTION", operator);
        },
        clearSelection ({ commit, dispatch }) {
            dispatch("actions/clearSelection", null, { root: true });
            dispatch("cspActions/clearSelection", null, { root: true });
            commit("TREE_REMOVE_ENTITY_SELECTION");
        },
        clearSearch ({ commit }) {
            commit("TREE_SET_ENTITIES", []);
        },
        async updateOperator ({ commit, getters }, operator) {
            await UsersService.updateOperator(operator);
            const userNode = getters.getUserById(operator.Id);
            const fullName = `${operator.FirstName} ${operator.LastName}`;
            commit("SET_ITEM_NAME", { container: userNode, newName: fullName });
        },
        async addOperator ({ dispatch }, operator) {
            const response = await UsersService.createOperator(operator)
            operator.Id = response.data;
            dispatch("addOperatorTreeNode", operator);
        },
        addOperatorTreeNode ({ commit }, operator) {
            commit('ADD_TREE_ENTITY', { container: null, item: UserTreeModel.create(operator, NODE_TYPES.OPERATOR) });
        },
    },
    mutations: {
        ...TreeCommons.mutations,
        SET_LAST_ENTITY_SELECTION (state, { OperatorId }) {
            const operator = state.entities.find(x => x.Id === OperatorId);
            const lastFoundEntity = { type: NODE_TYPES.OPERATOR, payload: operator };

            if (lastFoundEntity) {
                lastFoundEntity.payload.Selected = true;
                state.selectedTreeItem = lastFoundEntity.payload;
            }
        },
    },
    getters: {
        getCount (state) {
            return state.entities.length;
        },
        getUserById: (state) => (id) => {
            return state.entities.find(user => user.Id === id);
        }
    }
}