import AxiosFactory from '@/infrastructure/axios-factory';

const axios = AxiosFactory.create();
const baseUrl = "/Printer";

export default {
    getCareRecipientList: () => axios.get(`${baseUrl}/CareRecipientList`),
    getSinglePrescriptionRow: (prescriptionHeadGuid, prescriptionRowGuid) =>
        axios.get(`${baseUrl}/SinglePrescriptionRow`, {
            params: {
                prescriptionHeadGuid: prescriptionHeadGuid,
                prescriptionRowGuid: prescriptionRowGuid
            }
        }),
    getPrescriptionHistory: (prescriptionHeadGuid, includeProps, languageObj, statuses, marketAreaCode, header) =>
        axios.get(`${baseUrl}/PrescriptionHistory`, {
            params: {
                prescriptionHeadGuid: prescriptionHeadGuid,
                includeProps: includeProps,
                culture: languageObj.culture,
                statuses: statuses,
                marketAreaCode: marketAreaCode
            },
            headers: header ? { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } : {}
        }),
    getCareRecipientOrderHistoryDetails: (customerOrderId, careRecipientId, languageObj, isCSP, header) =>
        axios.get(`${baseUrl}/CareRecipientOrderHistoryDetails`, {
            params: {
                customerOrderId: customerOrderId,
                careRecipientId: careRecipientId,
                culture: languageObj.culture,
                isCSP: isCSP
            },
            headers: header ? { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } : {}
        }),
    getCarePlaceOrderHistoryDetails: (customerOrderId, languageObj, isCSP, header) =>
        axios.get(`${baseUrl}/CarePlaceOrderHistoryDetails`, {
            params: {
                customerOrderId: customerOrderId,
                culture: languageObj.culture,
                isCSP: isCSP
            },
            headers: header ? { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } : {}
        }),
    getProductDetails: (id, therapyArea, clientId, languageObj) =>
        axios.get(`${baseUrl}/ProductDetails`, {
            params: {
                id: id,
                therapyArea: therapyArea,
                culture: languageObj.culture
            },
            headers: clientId ? { "om-client-id": clientId } : {}
        }),
    getSelectedProducts: (careRecipientId) =>
        axios.get(`${baseUrl}/HandleProducts`, {
            params: {
                careRecipientId: careRecipientId
            }
        }),
    getCalendarPerDay: (careRecipientId, carePlaceName, date) =>
        axios.get(`${baseUrl}/CalendarPerDay`, {
            params: {
                careRecipientId: careRecipientId,
                carePlaceName: carePlaceName,
                calendarDate: date
            }
        }),
    getCalendarPerWeek: (careRecipientId, carePlaceName, startDate, endDate) =>
        axios.get(`${baseUrl}/CalendarPerWeek`, {
            params: {
                careRecipientId: careRecipientId,
                carePlaceName: carePlaceName,
                calendarStartDate: startDate,
                calendarEndDate: endDate
            }
        }),
    getCalendarPerMonth: (careRecipientId, carePlaceName, startDate, endDate) =>
        axios.get(`${baseUrl}/CalendarPerMonth`, {
            params: {
                careRecipientId: careRecipientId,
                carePlaceName: carePlaceName,
                calendarStartDate: startDate,
                calendarEndDate: endDate
            }
        }),
    getClaimDetails: (claimId, languageObj) =>
        axios.get(`${baseUrl}/ProductClaimDetails`, {
            params: {
                productClaimId: claimId,
                culture: languageObj.culture
            }
        }),
}