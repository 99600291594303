export default {
  install (vue) {
    vue.component("NewUserWizard", () => import("./NewUserWizard.vue"));
    vue.component("OrganizationList", () => import("./OrganizationList.vue"));
    vue.component("UserRoles", () => import("./UserRoles.vue"));
    vue.component("UserSummary", () => import("./UserSummary.vue"));
    vue.component("UserList", () => import("./UserList.vue"));
    vue.component("EditUser", () => import("./Edit.vue"));
    vue.component("EditUserRoles", () => import("./EditRoles.vue"));
    vue.component("UserAuthMethods", () => import("./AuthMethods.vue"));
    vue.component("UserDisclaimer", () => import("./Disclaimer.vue"));
    vue.component("UserProductAvailability", () => import("./ProductAvailability.vue"));
    vue.component("Status", () => import("./Status.vue"));
    vue.component("EffectiveRoles", () => import("./EffectiveRoles.vue"));
    vue.component("InvoiceAreas", () => import("./InvoiceAreas.vue"));
    vue.component("EditInvoiceAreas", () => import("./EditInvoiceAreas.vue"));

  },
};
