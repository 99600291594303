<template>
  <form>
    <om-textinput
      type="text"
      v-model="$v.careUnit.Name.$model"
      :placeholder="$t('common.name')"
      :required="!$v.careUnit.Name.required"
      :validation-reader="$v.careUnit.Name"
      id="name"
      >{{ $t("common.name") }}</om-textinput
    >
  </form>
</template>

<script>
import { Guid } from "@/common/Guid";
import { CareUnitService } from "@/services";
import CareUnit from "@/models/CareUnit";
import { mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import { NODE_TYPES } from "@/common/node-types";

export default {
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      initModelLoaded: false,
      careUnit: {},
    };
  },
  created() {
    this.init();
  },
  validations: {
    careUnit: {
      Name: {
        required,
        minLength: minLength(2),
      },
    },
  },
  methods: {
    ...mapActions("organizations", ["addCareUnit", "updateCareUnit"]),
    init() {
      this.initModelLoaded = false;
      const payload = this.payload || {};
      if (payload.NodeType === NODE_TYPES.CARE_UNIT && payload.Id && payload.Id !== Guid.Empty) {
        this.$emit("loadingChanged", true);
        CareUnitService.getById(payload.Id)
          .then((response) => {
            if (response.data) {
              this.careUnit = CareUnit.create({ ...response.data, ClientId: payload.ClientId });
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 404) {
                this.careUnit = CareUnit.create(null);
              }
            }
          })
          .finally(() => {
            this.initModelLoaded = true;
            this.$emit("loadingChanged", false);
          });
      } else {
        this.careUnit = CareUnit.create({ ClientId: payload.Id });
        this.initModelLoaded = true;
      }
      this.$emit("enableCancel", false);
      this.$emit("setDirty", false);
    },
    async save() {
      this.$v.$touch();
      const errors = this.$v.careUnit.$anyError;
      if (errors) {
        return;
      }
      const payload = this.prepareData();
      try {
        this.$emit("loadingChanged", true);
        if (payload.Id) {
          await this.updateCareUnit(payload);
        } else {
          await this.addCareUnit(payload);
          this.$v.careUnit.Name.$model = null;
          this.$v.careUnit.Name.$reset();
        }
        return true;
      } catch {
        return false;
      } finally {
        this.$emit("loadingChanged", false);
      }
    },
    prepareData() {
      return {
        Id: this.careUnit.Id,
        ClientId: this.careUnit.ClientId,
        Name: this.$v.careUnit.Name.$model,
      };
    },
    cancel() {
      this.init();
    },
  },
  watch: {
    careUnit: {
      handler(newValue) {
        if (this.initModelLoaded === true && newValue.Name) {
          this.$emit("enableCancel", true);
          this.$emit("setDirty", true);
        }
      },
      deep: true,
      immediate: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
