import Vue from "vue";
import { jwtDecode } from "jwt-decode";

export class AuthenticatedUserModel {
    constructor() {
        throw new Error('Please use AuthenticatedUserModel.create.');
    }

    static fromJwt (token) {
        if (!token) {
            return null;
        }

        const payload = jwtDecode(token);

        const apps = Array.isArray(payload.app) ? payload.app : [payload.app];
        const permissions = Array.isArray(payload.role) ? payload.role : [payload.role];
        const mac = payload.mac ? parseInt(payload.mac) : payload.mac;
        const destination = {
            Id: parseInt(payload.sub),
            FirstName: payload.given_name,
            LastName: payload.family_name,
            Username: payload.name,
            Permissions: permissions,
            ClientId: payload.cid,
            MarketAreaCode: mac,
            PatientDataOwnerId: parseInt(payload.pdo),
            ImpersonatedUserId: null,
            ImpersonatedFullName: null,
            Applications: apps,
            Application: apps[0], // TODO: User can be in Principal and CSP... we need to create app switcher
            Email: payload.email,
            TwoFactorChallenge: payload.amr === "otp",
            DefaultCarePlaceId: payload.default_cpid
        };

        if (payload.imp_u_id) {
            destination.ImpersonatedUserId = payload.imp_u_id;
            destination.ImpersonatedFullName = `${payload.imp_fn} ${payload.imp_ln}`;
        }

        return Object.preventExtensions(Vue.observable(destination));
    }
}