import AxiosFactory from "@/infrastructure/axios-factory";

const axios = AxiosFactory.create();
const careRecipientShoppingCart = `/api/ShoppingCart/CareRecipient`;
const guideBff = `/api/Guide/ShoppingCart`;

export default {
    CareRecipient: {
        create: (data, header) => axios.post(`${careRecipientShoppingCart}`, data,
            header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
        get: (careRecipientId, header) => axios.get(`${careRecipientShoppingCart}/${careRecipientId}`,
            header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
        getWithoutPrescriptions: (careRecipientId) => axios.get(`${guideBff}/ProductsWithoutPrescriptions/${careRecipientId}`),
        getHistory: (careRecipientId, header) => axios.get(`${careRecipientShoppingCart}/History/${careRecipientId}`,
            header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
        updateStatus: (data) => axios.put(`${careRecipientShoppingCart}/UpdateStatus`, data, {
            headers: { "Therapy-Area": data.TherapyArea }
        }),
        UpdateFlavorStatus: (data) => axios.put(`${careRecipientShoppingCart}/UpdateFlavorStatus`, data),
        updateConsumption: (data) => axios.put(`${careRecipientShoppingCart}/UpdateConsumption`, data, {
            headers: { "Therapy-Area": data.TherapyArea }
        }),
        UpdateFlavorConsumption: (data) => axios.put(`${careRecipientShoppingCart}/UpdateFlavorConsumption`, data),
        delete: (data) => axios.post(`${careRecipientShoppingCart}/Delete`, data),
        deleteFlavors: (data) => axios.post(`${careRecipientShoppingCart}/DeleteFlavors`, data),
        getSettingsForHandleProductsPage: (data) => axios.get(`${guideBff}/HandleProducts/settings`, { params: data }),
        replaceItem: (data) => axios.put(`${careRecipientShoppingCart}/ReplaceItem`, data),
    }
}