import AxiosFactory from "@/infrastructure/axios-factory";

const axios = AxiosFactory.create();

const url = "api/StaticContent";
const videoUrl = `StaticContent/api/Video`;

export default {
  getContentById: (id) => axios.get(`${url}/${id}`),
  create: (data) => axios.post(`${url}`, data),
  update: (data) => axios.put(`${url}`, data),
  delete: (id) => axios.delete(`${url}/Delete/${id}`),
  getAll: () => axios.get(`${url}`),
  getVideo: (id, lang) => axios.get(`${videoUrl}/${id}/${lang}`),
  getMaintenance: (lang) => axios.get(`${url}/Maintenance/${lang}`),
  getDisclaimer: (lang) => axios.get(`${url}/Disclaimer/${lang}`),
};
