const initialState = {
    loaderCount: 0
}

export default {
    namespaced: true,
    state: initialState,
    actions: {
        startLoading ({ commit }) {
            commit("ADD_LOADER");
        },
        stopLoading ({ commit }) {
            commit("REMOVE_LOADER")
        }
    },
    mutations: {
        ADD_LOADER: (state) => {
            state.loaderCount = state.loaderCount + 1;
        },
        REMOVE_LOADER: (state) => {
            state.loaderCount = state.loaderCount - 1;
        }
    },
    getters: {
        isLoading: (state) => state.loaderCount > 0
    }
}