import Vue from "vue";

export const queryInputElement = (el) => (el.querySelector("input") || el);

export const Focus = {
    inserted: function (el, binding) {
        const inputElement = queryInputElement(el);
        if (binding.value?.setFocus) {
            inputElement.focus();
        }
    },
    update: function (el, binding) {
        const inputElement = queryInputElement(el);
        Vue.nextTick(function () {
            if (binding.value?.setFocus) {
                inputElement.focus();
            }
        })
    }
};