import Vue from "vue";
import VueI18n from "vue-i18n";
import en_locale from "@/locale/en.json";
import axios from "axios";
import { dateTimeFormats } from '../locale/formats'

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "en",
  dateTimeFormats,
  fallbackLocale: "en",
  messages: {
    en: {
      ...en_locale
    }
  }
});

const loadedLanguages = ["en"];


function setI18nLanguage (lang) {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export function loadLanguageAsync (lang) {

  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(`@/locale/${lang}.json`).then(json => {
        i18n.setLocaleMessage(lang, json);
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
      });
    }

    return Promise.resolve(setI18nLanguage(lang));
  }



  return Promise.resolve(lang);
}
