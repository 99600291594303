import Vue from "vue";
import Vuex from "vuex";
import store from "@/store";
import router from "@/router";
import App from "@/App.vue";
import Toasted from "vue-toasted";
import { registerFilters } from "@/filters";
import ClientForms from "@/components/Client";
import ProductSelection from "@/components/ProductSelection";
import ProductCommon from "@/components/ProductCommon";
import Order from "@/components/Order/";
import CareUnitForms from "@/components/CareUnit";
import CarePlaceForms from "@/components/CarePlace";
import CommonComponents from "@/components/common";
import UserCommon from "@/components/UserCommon";
import UserComponents from "@/components/Users";
import OperatorComponents from "@/components/Operators";
import Vuelidate from "vuelidate";
import "@/index.scss";
import wysiwyg from "vue-wysiwyg";
import VModal from "vue-js-modal";
import { abilitiesPlugin } from "@casl/vue";
import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { i18n } from "@/locale";
import VueCookies from "vue-cookies";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
import BusPlugin from "@/infrastructure/bus-plugin";
import LocalStorage from "@/infrastructure/localStorage";
import SessionStorage from "@/infrastructure/sessionStorage";
import OMPanelPlugin from "@/om-panel-plugin";
import VTooltip from "v-tooltip";
import { Plugin } from "vue-fragment";
import { Capitalize } from "@/directives/capitalize";
import { IMask } from "@/directives/imask";
import { Focus } from "@/directives/focus";
import IdleVue from 'idle-vue';

Vue.directive("capitalize", Capitalize);
Vue.directive("imask", IMask);
Vue.directive("focus", Focus);

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(Vuex);
Vue.use(Order);
Vue.use(ClientForms);
Vue.use(CareUnitForms);
Vue.use(CarePlaceForms);
Vue.use(CommonComponents);
Vue.use(ProductSelection);
Vue.use(ProductCommon);
Vue.use(UserCommon);
Vue.use(UserComponents);
Vue.use(OperatorComponents);
Vue.use(Toasted, { duration: 3000 });
Vue.use(Vuelidate);
Vue.use(wysiwyg, {
  hideModules: { unorderedList: true },
  maxHeight: "300px",
});
Vue.use(VModal, { dialog: true });
Vue.use(abilitiesPlugin, store.getters["account/ability"], {
  useGlobalProperties: true,
});
Vue.use(VueFormWizard);
Vue.use(VueQuillEditor);
Vue.use(BusPlugin);
Vue.use(LocalStorage);
Vue.use(SessionStorage);
Vue.use(OMPanelPlugin);
Vue.use(VTooltip);
Vue.use(Plugin);

const eventsHub = new Vue();
const expireTimeInMinutes = (appConfig.expireSessionTime - 2) * 60000;
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: expireTimeInMinutes,
  startAtIdle: false,
});

let lang = navigator.language || navigator.userLanguage;
const localLang = Vue.$localStorage.getItem("language", null);
if (localLang !== null) {
  lang = localLang;
}

store.dispatch("language/setLanguage", lang);

window.process = { env: {} }

registerFilters();
const instance = new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App),
  onIdle () {
    const isAuthenticated = store.getters["account/isAuthenticated"];
    if (isAuthenticated) {
      this.$modal.show("session-expiration", {
        title: "sessionExpiration.sessionTimeoutTitle",
        text: "sessionExpiration.sessionTimeoutMsg",
        extendSession: true,
      });
    }
  }
});
instance.$mount("#app");

