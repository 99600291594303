const queryInputElement = (el) => (el.querySelector("input") || el);

export const Capitalize = {
    bind (el) {
        const inputElement = queryInputElement(el);
        inputElement._handleCapitalize = (e) => {
            // Remove extra spaces from a string
            e.target.value = e.target.value.trim().replace(/\s+/g, ' ');
            // Capitalize the first letter of each word
            e.target.value = e.target.value.replace(/(^\w{1})|(\s|\p{P})\w{1}/g, (letter) => letter.toUpperCase());
            e.target.dispatchEvent(new Event("input", { bubbles: true }));
        }
        inputElement.addEventListener('change', inputElement._handleCapitalize, true);
    },
    unbind (el) {
        const inputElement = queryInputElement(el);
        inputElement.removeEventListener('change', inputElement._handleCapitalize);
    }
}