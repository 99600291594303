import { TreeNode } from "./TreeNode";
import { NODE_TYPES } from "@/common/node-types";
import Vue from "vue";
import CarePlace from "@/models/CarePlace.js";

export default class CareUnit extends TreeNode {
    constructor() {
        throw new Error('Please use CareUnit.create.');
    }

    static create (data) {
        data = data || {};
        const treeNode = new TreeNode(NODE_TYPES.CARE_UNIT, data.ChildCount);
        treeNode.Id = data.Id;
        treeNode.Name = data.Name;
        treeNode.ClientId = data.ClientId;
        treeNode.$children = (data.Children || []).map(x => {
            x.ClientId = treeNode.ClientId;
            x.CareUnitId = treeNode.Id;
            return CarePlace.create(x)
        });
        treeNode.Children = treeNode.$children;
        treeNode.Expanded = treeNode.$children.length > 0 ? true : false;
        treeNode.ChildrenLoaded = treeNode.Children.length !== 0 || treeNode.Children.length === treeNode.ChildCount;
        return Object.preventExtensions(Vue.observable(treeNode));
    }
}