import { AccountService } from "@/services/index";
import { PublicClientApplication } from "@azure/msal-browser";

/* global appConfig */
const AADAuthenticationHandler = function () {
    const _instance = {};

    function getClientId () {
        if (appConfig.AAD) {
            return appConfig.AAD.clientId;
        }
        else if (process.env.VUE_APP_OM_AAD_CLIENTID) {
            return process.env.VUE_APP_OM_AAD_CLIENTID;
        }
        else return 0;
    }

    function getTenantId () {
        if (appConfig.AAD) {
            return appConfig.AAD.tenantId;
        }
        else if (process.env.VUE_APP_OM_AAD_TENANTID) {
            return process.env.VUE_APP_OM_AAD_TENANTID;
        }
        else return 0;
    }

    _instance.config = {
        auth: {
            clientId: getClientId(),
            authority: `https://login.microsoftonline.com/${getTenantId()}`,
            redirectUri: (appConfig.AAD) ? appConfig.AAD.redirectUrl : ``,
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: false,
                secureCookie: false
            }
        }
    };

    _instance.login = async function () {
        try {
            const scopesResponse = await AccountService.scopesAAD();
            const request = {
                scopes: scopesResponse.data
            };
            const msalInstance = new PublicClientApplication(_instance.config);
            await msalInstance.initialize();
            const response = await msalInstance.loginPopup(request);
            return response;
        } catch (err) {
            console.error(err)
        }
    };

    return {
        login: _instance.login
    };
}();

export default AADAuthenticationHandler;