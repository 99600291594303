export default {
    install (vue) {
        if (!vue.$localStorage) {
            window.localStorage.setAsJson = (key, value) => {
                if (!value) {
                    return;
                }
                window.localStorage.setItem(key, JSON.stringify(value));
            };
            window.localStorage.getFromJson = (key) => {
                const value = window.localStorage.getItem(key);
                return value && JSON.parse(value);
            };
            vue.$localStorage = window.localStorage;
            vue.prototype.$localStorage = window.localStorage;
        }
    }
}