import { TreeNode } from "./TreeNode";
import { NODE_TYPES } from "@/common/node-types";
import Vue from "vue";

export default class CarePlace extends TreeNode {
    constructor() {
        throw new Error('Please use CarePlace.create.');
    }

    static create (data) {
        data = data || {};
        const treeNode = new TreeNode(NODE_TYPES.CARE_PLACE, data.ChildCount);
        treeNode.Id = data.Id;
        treeNode.Name = data.Name;
        treeNode.Email = data.Email;
        treeNode.Phone = data.Phone || "";
        treeNode.CareUnitId = data.CareUnitId;
        treeNode.ClientId = data.ClientId;
        return Object.preventExtensions(Vue.observable(treeNode));
    }
}