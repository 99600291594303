import { i18n } from "@/locale/index.js"
export default {
    namespaced: true,
    state: {
        type: null,
        showDialog: false,
        showNotification: false,
        dialogType: null,
        title: null,
        message: null
    },
    actions: {
        customDialog ({ commit }, customData) {
            commit('setDialogState', true);
            commit("customDialog", {
                customData
            })
            commit('setDialogType', 'custom');
        },
        successNotification ({ commit }) {
            commit('success');
            commit('setNotificationState', true);
        },
        errorNotification ({ commit }) {
            commit('error');
            commit('setNotificationState', true);
        },
        clearNotification ({ commit }) {
            commit('clear');
            commit('setNotificationState', false);
        },

        showSuccessDialog ({ commit }) {
            commit('setDialogState', true);
            commit('success');
        },
        showErrorDialog ({ commit }) {
            commit('setDialogState', true);
            commit("error");
        },
        hideDialog ({ commit }) {
            commit('setDialogState', false);
            commit('clear');
        },

    },
    mutations: {
        customDialog (state, { customData }) {
            state.title = customData.title;
            state.message = customData.message;
            state.type = customData.type;
        },
        success (state) {
            state.type = 'success';
            state.message = i18n.t("notifications.saveSuccess");
            state.title = i18n.t("common.information")
        },
        error (state) {
            state.type = 'error';
            state.message = i18n.t("notifications.saveFail");
            state.title = i18n.t("common.information")
        },
        clear (state) {
            state.type = null;
            state.message = null;
            state.title = null;
        },
        setDialogState (state, visibility) {
            state.showDialog = visibility;
        },
        setDialogType (state, type) {
            state.dialogType = type;
        },
        setNotificationState (state, visibility) {
            state.showNotification = visibility;
        },
    }
}
