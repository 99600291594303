const FORMAT_STYLE = {
  NUMERIC: { value: "numeric" },
  SHORT: { value: "short" },
  LONG: { value: "long" }
};

const dateTimeFormats = {
  "en-US": {
    numeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: 'numeric',
      minute: 'numeric',
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  "fi": {
    numeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: 'numeric',
      minute: 'numeric',
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  "sv": {
    numeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: 'numeric',
      minute: 'numeric',
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true
    }
  },
  "dk": {
    numeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: 'numeric',
      minute: 'numeric',
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true
    }
  }
};


const numberFormats = {
  "en-US": {
    currency: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol"
    }
  },
  "sv": {
    currency: {
      style: "currency",
      currency: "SEK",
      currencyDisplay: "symbol"
    }
  },
  "dk": {
    currency: {
      style: "currency",
      currency: "DKK",
      currencyDisplay: "symbol"
    }
  }
};

export { FORMAT_STYLE, dateTimeFormats, numberFormats };
