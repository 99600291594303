import Vue from "vue";

export default {
    namespaced: true,
    state: {
        newSelectedProducts: Object.create(null),
        comparedProducts: Object.create(null)
    },
    actions: {
        setCompare ({ commit }, { product, add }) {
            if (add) {
                commit("ADD_COMPARE", product);
                return;
            }

            commit("REMOVE_COMPARE", product)
        },
        setSelection ({ commit }, { product, add }) {
            if (add) {
                commit("ADD_SELECTION", product);
                return;
            }

            commit("REMOVE_SELECTION", product);
        },
        resetCompare ({ commit }) {
            commit("RESET_COMPARE")
        },
        resetSelection ({ commit }) {
            commit("RESET_SELECTION");
        },
        resetAll ({ dispatch }) {
            dispatch("resetCompare");
            dispatch("resetSelection");
        }

    },
    mutations: {
        ADD_COMPARE (state, product) {
            product.Compared = true;
            Vue.set(state.comparedProducts, product.ItemNumber, product);
        },
        REMOVE_COMPARE (state, product) {
            product.Compared = false;
            Vue.delete(state.comparedProducts, product.ItemNumber);
        },
        RESET_COMPARE (state) {
            for (const [k, v] of Object.entries(state.comparedProducts)) {
                v.Compared = false;
                Vue.delete(state.comparedProducts, k);
            }
        },
        ADD_SELECTION (state, product) {
            product.Selected = true;
            Vue.set(state.newSelectedProducts, product.ItemNumber, product);
        },
        REMOVE_SELECTION (state, product) {
            product.Selected = false;
            Vue.delete(state.newSelectedProducts, product.ItemNumber);
        },
        RESET_SELECTION (state) {
            for (const [k, v] of Object.entries(state.newSelectedProducts)) {
                v.Selected = false;
                Vue.delete(state.newSelectedProducts, k);
            }
        }
    },
    getters: {
        comparedProducts: (state) => Object.entries(state.comparedProducts).map(([, v]) => v),
        newSelectedProducts: (state) => Object.entries(state.newSelectedProducts).map(([, v]) => v)
    }
}