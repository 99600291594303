export class TreeNode {
    constructor(nodeType, childCount) {
        this.Selected = false;
        this.Expanded = false;
        this.IsLoading = false;
        this.Children = [];
        this.$children = [];
        this.ChildCount = childCount || 0;
        this.NodeType = nodeType;
        this.ChildrenLoaded = false || this.ChildCount === 0;
    }
}