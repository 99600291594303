export const state = {
    selectedTreeItem: undefined,
    entities: []
}

export const mutations = {
    TREE_SET_CHILD_ENTITIES (state, { container, childEntities }) {
        container.ChildrenLoaded = true;
        container.Children.push(...childEntities);
    },
    REMOVE_CLIENT_FROM_TREE (state, clientId) {
        state.entities.forEach((val, i, arr) => {
            if (val.Id === clientId) {
                arr.splice(i, 1);
            }
        });
        state.selectedTreeItem = {};
    },
    TREE_SET_ENTITIES (state, entities) {
        state.entities = entities;
        const selectedItem = state.selectedTreeItem;
        if (selectedItem) {
            state.entities.forEach(item => {
                if (selectedItem.Id === item.Id) {
                    item.Selected = true;
                }
            });
        }
    },
    ADD_TREE_ENTITY (state, { container, item }) {
        let items = state.entities;
        if (container) {
            items = container.Children;
        }

        items.push(item);
        items.sort((a, b) => a.Name.localeCompare(b.Name));
        if (container) {
            container.Children = items;
            container.ChildCount = items.length;
            return;
        }
        state.entities = items;
    },
    INCREASE_CHILD_COUNT (state, container) {
        container.ChildCount = container.ChildCount + 1;
    },
    TREE_REMOVE_ENTITY_SELECTION (state) {
        if (state.selectedTreeItem) {
            const selectedItem = state.selectedTreeItem;
            state.entities.forEach(item => {
                if (selectedItem.Id === item.Id) {
                    return item.Selected = false;
                }
            });
            state.selectedTreeItem.Selected = false;
            state.selectedTreeItem = undefined;
        }
    },
    TREE_SET_ENTITY_SELECTION (state, newSelection) {
        newSelection.Selected = true
        state.selectedTreeItem = newSelection;
        state.selectedTreeItem.Selected = true;
    },
    SET_ITEM_NAME (state, { container, newName }) {
        container.Name = newName;
    }
}