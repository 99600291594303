export default {
    namespaced: true,
    state: {
        isDirty: false,
    },
    actions: {
        setDirty ({ commit }) {
            commit('setDirty');
        },
        setClear ({ commit }) {
            commit('setClear');
        }
    },
    mutations: {
        setDirty (state) {
            state.isDirty = true;
        },
        setClear (state) {
            state.isDirty = false;
        }
    }
}