import Vue from "vue";
import { i18n, loadLanguageAsync } from "@/locale";
import LocalStorage from "@/infrastructure/localStorage";

Vue.use(LocalStorage);


export default {
  namespaced: true,
  state: {
    language: Vue.$localStorage.getItem("language"),
    availableLanguages: [
      { code: 'en', image: () => require('@/assets/flags/gb.svg'), i18n: "language.en", culture: 'en-US' },
      { code: 'sv', image: () => require('@/assets/flags/se.svg'), i18n: "language.sv", culture: 'sv-SE' },
      { code: 'fi', image: () => require('@/assets/flags/fi.svg'), i18n: "language.fi", culture: 'fi-FI' },
      { code: 'dk', image: () => require('@/assets/flags/dk.svg'), i18n: "language.dk", culture: 'da-DK' }
    ]
  },
  mutations: {
    SET_LANGUAGE (state, lang) {
      Vue.$localStorage.setItem("language", lang);
      state.language = lang;
      i18n.locale = lang;
    }
  },
  actions: {
    async setLanguage ({ commit, state }, lang) {
      let refined_lang;
      if (lang.split("-").length > 1) {
        refined_lang = lang.split("-")[0]
      } else {
        refined_lang = lang.toLowerCase();
      }

      const exists = state.availableLanguages.find(x => x.code == refined_lang);
      if (!exists) {
        refined_lang = "en";
      }

      await loadLanguageAsync(refined_lang);
      commit("SET_LANGUAGE", refined_lang);
    }
  },
  getters: {
    choosableLanguages: (state) => {
      state.availableLanguages.filter(lang => {
        return lang.code !== state.language
      });
      return state.availableLanguages;
    },
    languageObj: (state) => {
      return state.availableLanguages.find(lang => lang.code === state.language);
    },
  }
};
