import AxiosFactory from '@/infrastructure/axios-factory';

const axios = AxiosFactory.create();
const iterativePrescriptionUrl = '/api/IterativePrescription';
const clientSettingsUrl = '/api/IterativePrescription/ClientSettings';
const carePlaceSettingsUrl = '/api/IterativePrescription/CarePlaceSettings';
const prescription = '/api/IterativePrescription/Prescription';
const invoiceAreaUrl = '/api/IterativePrescription/InvoiceArea';

export default {
    Settings: {
        upsertClientSettings: data => axios.post(`${clientSettingsUrl}/upsert`, data),
        getClientSettingsByClientId: clientId => axios.get(`${clientSettingsUrl}/${clientId}`),
        upsertCarePlaceSettings: data => axios.post(`${carePlaceSettingsUrl}/upsert`, data),
        getCarePlaceSettingsByCarePlaceId: carePlaceId => axios.get(`${carePlaceSettingsUrl}/${carePlaceId}`),
        getPrintPrescriptionProperties: clientId => axios.get(`${clientSettingsUrl}/PrintPrescriptionProperties/${clientId}`),
    },
    Prescriptions: {
        create: (data, header) => axios.post(`${prescription}/Create`, data, header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId } } : {}),
        update: (data, header) => axios.put(`${prescription}/Update`, data, header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId } } : {}),
        updateDispense: (data, header) => axios.put(`${prescription}/UpdateDispense`, data, header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId } } : {}),
        softUpdate: (data, header) => axios.put(`${prescription}/SoftUpdate`, data, header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId } } : {}),

        getActiveByCareRecipientId: (data) => axios.post(`${prescription}/Search`, data),
        closeHead: (data, header) => axios.put(`${prescription}/Close`, data, header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId } } : {}),
        closeRow: (data, header) => axios.put(`${prescription}/CloseRow`, data, header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId } } : {}),
        getHeadHistory: (data) => axios.post(`${prescription}/History`, data),
        getRowsHistory: (data) => axios.post(`${prescription}/HistoryItem/`, data),
        renew: (data) => axios.post(`${prescription}/Renew`, data),
        delete: (data, header) => axios.post(`/api/Guide/Prescription`, data, header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId } } : {}),
        setApplicableDate: (data) => axios.put(`${prescription}/ApplicabilityDate`, data),
        hasActivePrescriptions: careRecipientGuid => axios.get(`${prescription}/HasActivePrescriptions/${careRecipientGuid}`)
    },
    PatientFee: {
        upsert: (data) => axios.post(`${iterativePrescriptionUrl}/PatientFee/upsert`, data),
        history: (data) => axios.get(`${iterativePrescriptionUrl}/PatientFeeHistory/${data.TherapyArea}/${data.CareRecipientId}`),
        get: (data) => axios.get(`${iterativePrescriptionUrl}/PatientFee/${data.TherapyArea}/${data.CareRecipientId}`),
    },
    CSP: {
        getActiveByCareRecipientId: (data) => axios.post(`${prescription}/Search`, data, { headers: { "om-client-id": data.ClientId, } }),
    },
    InvoiceArea: {
        getInvoiceAreasByCode: (data) => axios.get(`${invoiceAreaUrl}/GetByCode`, { params: data }),
        getInvoiceAreasByUserCustomerNumber: (data) => axios.post(`${invoiceAreaUrl}/GetInvoiceAreasByUCN`, data),
        getInvoiceAreasByCustomerNumber: (data) => axios.post(`${invoiceAreaUrl}/GetByCustomerNumber`, data),
    }
}