import AxiosFactory from '@/infrastructure/axios-factory';

const axios = AxiosFactory.create(true);
const baseUrl = '/api/Investigation';

export default {
    createInvestigation: (data) => axios.post(`${baseUrl}`, data),
    updateInvestigationStatus: (data) => axios.put(`${baseUrl}`, data),
    getById: (id) => axios.get(`${baseUrl}/${id}`),
    getByCareRecipient: (careRecipientId, therapyArea = null, status = null) => axios.get(`${baseUrl}/GetByCareRecipient/${careRecipientId}/${therapyArea}/${status}`),
    createProductItem: (data) => axios.post("/api/Guide/Investigation", data,
        { headers: { "Therapy-Area": data.TherapyArea } }),
    setNextInvestigationDate: (data) => axios.put(`${baseUrl}/UpdateValidTo`, data),
    getInvestigationSelectedProducts: investigationId => axios.get(`/api/Guide/Investigation/SelectedProducts/${investigationId}`),
    getIncontinenceSpecific: careRecipientId => axios.get(`${baseUrl}/GetIncontinenceSpecific/${careRecipientId}`),
    saveIncontinenceSpecific: data => axios.post(`${baseUrl}/SaveIncontinenceSpecific`, data)
}