import AxiosFactory from '@/infrastructure/axios-factory';

const axios = AxiosFactory.create();

const ctrlUrl = `Identity/api/AuthenticationMethods`

export default {
    getByUserId: (userId) => axios.get(`${ctrlUrl}/${userId}`),
    updatePassword: (data) => axios.put(`${ctrlUrl}/password`, data),
    updatePhone: (data) => axios.put(`${ctrlUrl}/phone`, data),
    update2faEmail: (data) => axios.put(`${ctrlUrl}/email`, data),
    updateAD: (data) => axios.put(`${ctrlUrl}/ad`, data),
    updateSiths: (data) => axios.put(`${ctrlUrl}/siths`, data),
    updateUsername: (data) => axios.put(`${ctrlUrl}/username`, data),
    updateAuthMethods: (data) => axios.put(`${ctrlUrl}`, data)

}