import AxiosFactory from "@/infrastructure/axios-factory";

const axios = AxiosFactory.create();
const ctrlUrl = '/api/OrganizationUnit/CareUnit';

export default {
    create: data => axios.post(ctrlUrl, data),
    update: data => axios.put(ctrlUrl, data),
    getById: id => axios.get(`${ctrlUrl}/${id}`),
    getWithCount: clientId => axios.get(`${ctrlUrl}/node/${clientId}`),
    getRelatedNodesCUA: () => axios.get(`/api/Guide/CUA/CareUnits`),
}