import mitt from "mitt";
export default {
    install (Vue) {
        if (!Vue.prototype.$ompanel) {
            const ompanel = { visible: false };
            ompanel.bus = new mitt();
            ompanel.show = function (name, params) {
                params = params || {};
                ompanel.visible = true;
                ompanel.bus.emit("toggle", { name, params, visible: true });
            }
            ompanel.hide = function (name, params) {
                params = params || {};
                ompanel.visible = false;
                ompanel.bus.emit("toggle", { name, params, visible: false })
            }
            Vue.prototype.$ompanel = ompanel
        }
    }
}