import Vue from "vue";
import router from "@/router";
import { PureAbility } from "@casl/ability";
import { i18n } from "@/locale";
import SessionStorage from "@/infrastructure/sessionStorage";
import VueCookies from "vue-cookies";
import { AuthenticatedUserModel } from "@/models/AuthenticatedUserModel";
import { AccountService, CarePlaceService, PermissionService, UsersService } from "@/services";
import { Applications } from "@/common/Applications";

Vue.use(VueCookies);
Vue.use(SessionStorage);

const token = Vue.$sessionStorage.getItem("jwt");
const refreshToken = Vue.$sessionStorage.getItem("refresh_token");
const vm = new Vue();
const ability = new PureAbility();

const getAbilities = (permissions) => permissions.map((x) => ({ action: x }));
const getDefaultState = () => {
  ability.update([]);
  const obj = {
    invalidCredentials: false,
    loggedIn: false,
    loggingIn: false,
    user: null,
    ability: ability,
    sessionCarePlaceId: null,
    membership: [],
    defaultCarePlaceId: null,
    token,
    refreshToken,
  };

  if (token) {
    obj.loggedIn = true;
    obj.user = AuthenticatedUserModel.fromJwt(token);
    ability.update(getAbilities(obj.user.Permissions));
    obj.sessionCarePlaceId = Vue.$sessionStorage.getItem(`SCP-${obj.user.ImpersonatedUserId ?? obj.user.Id}`);
    obj.defaultCarePlaceId = obj.user.DefaultCarePlaceId;
  }
  return obj;
};

let initialState = getDefaultState();

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async login ({ commit }, { username, password }) {
      commit("LOGIN_START");
      commit("RESET_STATE");

      try {
        const response = await AccountService.login(username, password);
        const token = response.data.access_token;
        const user = AuthenticatedUserModel.fromJwt(token);
        commit("LOGIN_SUCCESS", { user, token, refreshToken: response.data.refresh_token });
        if (user.TwoFactorChallenge) {
          router.push({ name: "2fa" });
          return
        }

        commit("SET_ABILITIES", getAbilities(user.Permissions));
        if (user) {
          commit("SET_DEFAULT_CARE_PLACE", user.DefaultCarePlaceId);
        }
        router.push({ name: "root" }).catch((e) => console.error(e));
      } catch (error) {
        commit("LOGIN_FAIL", error);
        if (error.data?.title !== "ath0001") {
          vm.$modal.show("dialog", {
            title: i18n.t("common.systemMessage"),
            text:
              error.data?.title === undefined ? i18n.t(`errorCode.ath0001`) : i18n.t(`errorCode.${error.data?.title}`),
            buttons: [
              {
                title: i18n.t("common.ok"),
                class: "primary normal flex-none",
                handler: () => {
                  vm.$modal.hide("dialog");
                },
              },
            ],
          });
        }
      }
    },
    async logout ({ commit, dispatch, state }) {
      try {
        await AccountService.logout(state.refreshToken);
      }
      finally {
        dispatch("unselectCarePlace");
        commit("LOGOUT");
        commit("CLEAR_ABILITIES");
        commit("RESET_STATE");
        commit("SET_MEMBERSHIP", []);
        if (state.user?.ImpersonatedUserId) {
          commit("UNSELECT_DEFAULT_CARE_PLACE");
        }
        if (router.history.current.name !== "Login") {
          router.push({ name: "Login" }).catch((e) => console.error(e));
        }
      }
    },
    async consumeRefreshToken ({ commit, state }) {
      commit("CLEAR__TOKEN");
      const response = await AccountService.refreshToken(state.refreshToken);
      const token = response.data.access_token;
      const user = AuthenticatedUserModel.fromJwt(token);
      commit("LOGIN_SUCCESS", { user, token, refreshToken: response.data.refresh_token });
      commit("SET_ABILITIES", getAbilities(user.Permissions));
    },
    async loadMembership ({ commit, getters, dispatch }, userId) {
      const response = await PermissionService.getUserMemberShip(userId);
      commit("SET_MEMBERSHIP", response.data.MembershipNodes);
      if (getters.clientId) {
        await dispatch("loadCarePlaceCustomerNumberPairs", getters.clientId);
      }
    },
    async selectCarePlace ({ commit, getters }, carePlaceId) {
      const response = await AccountService.changeCarePlace(carePlaceId);
      const token = response.data.access_token;
      const user = AuthenticatedUserModel.fromJwt(token);
      commit("LOGIN_SUCCESS", { user, token, refreshToken: response.data.refresh_token });
      commit("SET_ABILITIES", getAbilities(user.Permissions));
      Vue.$sessionStorage.setItem(getters.cookieName, carePlaceId);
      commit("SELECT_CARE_PLACE", carePlaceId);
    },
    async loadCarePlaceCustomerNumberPairs ({ commit }, clientId) {
      const response = await CarePlaceService.getCareplaceCustomerNumberPairs(clientId);
      commit("SET__CARE_PLACE__CUSTOMER_NUMBER__PAIRS", response.data.Pairs);
    },
    async impersonate ({ commit }, { impersonatingUserId, carePlaceId }) {
      const response = await AccountService.impersonate(impersonatingUserId);
      const token = response.data.access_token;
      const user = AuthenticatedUserModel.fromJwt(token);
      commit("LOGIN_SUCCESS", { user, token, refreshToken: response.data.refresh_token });
      commit("SET_ABILITIES", getAbilities(user.Permissions));

      if (user) {
        commit("SET_DEFAULT_CARE_PLACE", carePlaceId);
      }
      router.push({ name: "root" }).catch((e) => console.error(e));
    },
    async stopImpersonate ({ commit }) {
      const response = await AccountService.stopImpersonate();
      const token = response.data.access_token;
      const user = AuthenticatedUserModel.fromJwt(token);
      commit("LOGIN_SUCCESS", { user, token, refreshToken: response.data.refresh_token });
      commit("SET_ABILITIES", getAbilities(user.Permissions));
      commit("UNSELECT_CARE_PLACE");
      commit("UNSELECT_DEFAULT_CARE_PLACE");
      commit("SET_MEMBERSHIP", []);
      router.push({ name: "root" }).catch((e) => console.error(e));
    },
    async loginUsingCard ({ commit }, { siths, grandidsession }) {
      commit("LOGIN_START");
      commit("RESET_STATE");

      try {
        const response = await AccountService.loginUsingCard(siths, grandidsession);
        const token = response.data.access_token;
        const user = AuthenticatedUserModel.fromJwt(token);
        commit("LOGIN_SUCCESS", { user, token, refreshToken: response.data.refresh_token });
        commit("SET_ABILITIES", getAbilities(user.Permissions));
        if (user) {
          commit("SET_DEFAULT_CARE_PLACE", user.DefaultCarePlaceId);
        }
        router.push({ name: "root" }).catch((e) => console.error(e));
      } catch (error) {
        commit("LOGIN_FAIL", error);
        if (router.history.current.name !== "Login") {
          router.push({ name: "Login" }).catch((e) => console.error(e));
        }
      }
    },
    async loginAAD ({ commit }, bearerToken) {
      commit("LOGIN_START");
      commit("RESET_STATE");

      try {
        const response = await AccountService.loginAAD(bearerToken);
        const token = response.data.access_token;
        const user = AuthenticatedUserModel.fromJwt(token);
        commit("LOGIN_SUCCESS", { user, token, refreshToken: response.data.refresh_token });
        commit("SET_ABILITIES", getAbilities(user.Permissions));
        router.push({ name: "root" }).catch((e) => console.error(e));
      } catch (error) {
        commit("LOGIN_FAIL", error);
        if (router.history.current.name !== "Login") {
          router.push({ name: "Login" }).catch((e) => console.error(e));
        }
      }
    },
    async twoFactorChallenge ({ commit }, { code, username }) {
      const response = await AccountService.twoFactorLogin(code, username);
      const token = response.data.access_token;
      const user = AuthenticatedUserModel.fromJwt(token);
      commit("LOGIN_SUCCESS", { user, token, refreshToken: response.data.refresh_token });
      commit("SET_ABILITIES", getAbilities(user.Permissions));
      if (user) {
        commit("SET_DEFAULT_CARE_PLACE", user.DefaultCarePlaceId);
      }
      router.push({ name: "root" }).catch((e) => console.error(e));
    },
    unselectCarePlace ({ commit }) {
      // TODO: Change care place
      commit("UNSELECT_CARE_PLACE");
    },
    async setDefaultCarePlace ({ commit, state }, carePlace) {
      commit("SET_DEFAULT_CARE_PLACE", carePlace.Id);
      if (!state.user.ImpersonatedUserId) {
        await UsersService.setDefaultCarePlace({ UserId: state.user.Id, CarePlaceId: carePlace.Id })
      }
    },
    async unsetDefaultCarePlace ({ commit, state }) {
      if (!state.user.ImpersonatedUserId) {
        await UsersService.setDefaultCarePlace({ UserId: state.user.Id, CarePlaceId: null })
      }
      commit("SET_DEFAULT_CARE_PLACE");
    },
  },
  mutations: {
    LOGIN_START (state) {
      state.loggingIn = true;
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState());
    },
    LOGIN_SUCCESS (state, { user, token, refreshToken }) {
      state.invalidCredentials = false;
      state.loggedIn = true;
      state.user = user;
      state.token = token;
      state.refreshToken = refreshToken;
      Vue.$sessionStorage.setItem("jwt", token);
      Vue.$sessionStorage.setItem("refresh_token", refreshToken);
    },
    LOGIN_FAIL (state, error) {
      state.invalidCredentials = error.data?.title === "ath0001" ? true : false;
      state.loggedIn = false;
      state.user = null;
      state.token = null;
      state.refreshToken = null;
      Vue.$sessionStorage.removeItem("jwt");
      Vue.$sessionStorage.removeItem("refresh_token");
    },
    LOGOUT (state) {
      state.loggedIn = false;
      state.user = null;
      state.token = null;
      state.refreshToken = null;
      Vue.$sessionStorage.removeItem("jwt");
      Vue.$sessionStorage.removeItem("refresh_token");
      Vue.$sessionStorage.removeItem("CR-previousSearch");
      Vue.$sessionStorage.removeItem("expires_at");
    },
    SET_ABILITIES (state, abilities) {
      state.ability.update(abilities);
    },
    CLEAR_ABILITIES (state) {
      state.ability.update([]);
    },
    SELECT_CARE_PLACE (state, carePlaceId) {
      state.sessionCarePlaceId = carePlaceId;
    },
    SET_DEFAULT_CARE_PLACE (state, carePlaceId) {
      state.defaultCarePlaceId = carePlaceId;
    },
    UNSELECT_CARE_PLACE (state) {
      state.sessionCarePlaceId = null;
    },
    UNSELECT_DEFAULT_CARE_PLACE (state) {
      state.defaultCarePlaceId = null;
    },
    SET_MEMBERSHIP (state, membership) {
      state.membership = membership;
    },
    SET__CARE_PLACE__CUSTOMER_NUMBER__PAIRS (state, pairs) {
      const carePlaces = state.membership.filter((x) => x.Label === "CarePlace");
      for (const carePlace of carePlaces) {
        const pair = pairs.find((x) => x.CarePlaceId === carePlace.Id);
        if (pair) {
          carePlace.PrimaryCustomerNumber = pair.CustomerNumber;
        }
      }
    },
    CLEAR__TOKEN (state) {
      state.token = null;
      Vue.$sessionStorage.removeItem("jwt");
    },
  },
  getters: {
    token: (state) => state.token,
    ability: (state) => state.ability,
    marketAreaCode: (state) => state.user?.MarketAreaCode,
    fullName: (state) => `${state.user?.FirstName} ${state.user?.LastName}`,
    isGuest: (state) => !state.user,
    userId: (state) => state.user?.Id,
    currentUserId: (state) => state.user?.ImpersonatedUserId ? state.user?.ImpersonatedUserId : state.user?.Id,
    user: (state) => {
      return {
        FirstName: state.user?.FirstName,
        LastName: state.user?.LastName,
        Email: state.user?.Email,
        Username: state.user?.Username
      }
    },
    isAuthenticated: (state) => state.loggedIn,
    cookieName: (state) => `SCP-${state.user?.ImpersonatedUserId ?? state.user?.Id}`,
    sessionCarePlaceId: (state) => state.sessionCarePlaceId,
    sessionCarePlace: (state, getters) =>
      state.sessionCarePlaceId ? getters.carePlaces.find((x) => x.Id === state.sessionCarePlaceId) : null,
    carePlaces: (state) => state.membership.filter((v) => v.Label === "CarePlace"),
    careUnits: (state) => state.membership.filter((v) => v.Label === "CareUnit"),
    defaultCarePlaceId: (state) => state.defaultCarePlaceId,
    application: (state) => state.user?.Application,
    isCSP: (state) => state.user?.Application === Applications.CSP,
    getOrganizationById: (state) => (organizationId) => {
      return state.membership.find((x) => x.Id === organizationId);
    },
    clientId: (state) => state.user?.ClientId,
    sessionCareUnit: (state, getters) => {
      return state.membership.find((x) => x.Label === "CareUnit" && x.Id === getters.sessionCarePlace?.ParentId);
    },
    sessionCareUnitId: (_, getters) => getters.sessionCareUnit.Id,
    fullCarePlaceName: (_, getters) => (carePlaceId) => {
      const carePlace = getters.carePlaces.find((x) => x.Id === carePlaceId);
      if (carePlace) {
        let fullName = `${carePlace.Name}`;
        if (carePlace.PrimaryCustomerNumber) {
          fullName = `${carePlace.PrimaryCustomerNumber} ${fullName}`;
        }
        const careUnit = getters.careUnits.find((x) => x.Id === carePlace.ParentId);
        if (careUnit) {
          fullName = `${fullName} (${careUnit.Name})`;
        }
        return fullName;
      }

      return "NA";
    },
    customerNumber: (_, getters) => (carePlaceId) => {
      const carePlace = getters.carePlaces.find((x) => x.Id === carePlaceId);
      if (carePlace) {
        return `${carePlace.PrimaryCustomerNumber}`;
      }

      return "NA";
    },
    isImpersonating: (state) => !!state.user?.ImpersonatedUserId,
  },
};
