import store from "@/store";

const RefreshTokenManager = function () {
    const _instance = {};
    _instance._promise = null;


    _instance.execute = function () {
        if (!_instance._promise) {
            _instance._promise =
                store
                    .dispatch("account/consumeRefreshToken")
                    .finally(function (value) {
                        _instance._promise = null;
                        return value;
                    });
        }

        return _instance._promise;
    };

    return {
        execute: _instance.execute
    }
}();

export default RefreshTokenManager;