import AxiosFactory from "@/infrastructure/axios-factory";

const axios = AxiosFactory.create();
const itemReplacementUrl = "/api/CSP/ItemReplacement";
const agreementsUrl = "/api/csp/ProductAgreement";
const careRecipientCSP = "/api/CSP/CareRecipient";
const itemAgreementUrl = "/api/CSP/ItemAgreements";

export default {
  ItemReplacement: {
    getItemReplacementHistory: (data) => axios.post(`${itemReplacementUrl}/GetReplacementHistory`, data),
    getItemReplacementInformation: (data) => axios.post(`${itemReplacementUrl}/GetReplacementInformation`, data),
    publishItemReplacement: (data) =>
      axios.post(`${itemReplacementUrl}`, {
        ...data,
      }),
  },
  CareRecipient: {
    copyMove: data => axios.put(`${careRecipientCSP}/CareRecipientCarePlace/CopyMove`, data, { headers: { "om-client-id": data.ClientId } }),
    activate: (data) => axios.put(`${careRecipientCSP}/Activate`, data)
  },
  getAgreementHistoryByItemNumber: (data) => {
    return axios.get(`${agreementsUrl}`, {
      params: data,
    });
  },
  getItemAgreement: data => {
    return axios.get(`${itemAgreementUrl}`, {
      params: data,
    });
  },
  searchCareRecipients: (data) => axios.post(`${careRecipientCSP}/Search`, data),
  getCarePlacesForClient: clientId => axios.get(`${careRecipientCSP}/GetCarePlacesForClient/${clientId}`),
  searchCareRecipientsBySSN: (data) => axios.post(`${careRecipientCSP}/SearchBySSN`, data),
  anonymizeCareRecipient: (data) => axios.put(`${careRecipientCSP}/Anonymize`, data, { headers: { "om-client-id": data.ClientId } }),
  getClients: (mac) => axios.get(`${careRecipientCSP}/GetClients/${mac}`),
};
