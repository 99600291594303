import AxiosFactory from '@/infrastructure/axios-factory';

const axios = AxiosFactory.create();
const baseUrl = "/Printer";

export default {
    getCareRecipientCopyList: () => axios.get(`${baseUrl}/CareRecipientCopyList`),
    getSelectedProductsForCopy: (careRecipientId) =>
        axios.get(`${baseUrl}/HandleProductsForCopy`, {
            params: {
                careRecipientId: careRecipientId
            }
        }),
    getOrderDetailsCopy: (customerOrderId, id, languageObj, header) =>
        axios.get(`${baseUrl}/OrderDetailsCopy`, {
            params: {
                customerOrderId: customerOrderId,
                id: id,
                culture: languageObj.culture
            },
            headers: header ? { "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } : {}
        }),
    getPrescriptionHistoryForCopy: (prescriptionHeadGuid, includeProps, languageObj, statuses, marketAreaCode, header) =>
        axios.get(`${baseUrl}/PrescriptionHistoryForCopy`, {
            params: {
                prescriptionHeadGuid: prescriptionHeadGuid,
                includeProps: includeProps,
                culture: languageObj.culture,
                statuses: statuses,
                marketAreaCode: marketAreaCode
            },
            headers: header ? { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } : {}
        }),
    getInvestigationHistoryDetailsForCopy: (investigationId, languageObj) =>
        axios.get(`${baseUrl}/InvestigationHistoryDetailsForCopy`, {
            params: {
                investigationId: investigationId,
                culture: languageObj.culture
            }
        }),
}