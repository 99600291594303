import AxiosFactory from "@/infrastructure/axios-factory";

const axios = AxiosFactory.create();
const productSearchApi = "Product/api/Search"
const categories = `api/Product/Search/Categories`;
const product = "api/Product";
const clientAgreementsUrl = "api/Principal/Agreement/ClientCounts"

export default {
    getProductByItemNumber: (data, header) => axios.get(`${product}/GetProductByItemNumber`, {
        params: data,
        headers: header ? { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } : {}
    }),
    getProductsByProductGroupNumber: (data, header) => axios.get(`${product}/GetProductsByProductGroupNumber`, {
        params: data,
        headers: header ? { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } : {}
    }),
    getNutritionFlavors: productNumbers => axios.post(`${product}/NutritionFlavors/`, { ProductNumbers: productNumbers }),
    getCategories: (data, header) => axios.post(`${categories}`, data,
        header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
    getClientAgreements: data => {
        return axios.get(`${clientAgreementsUrl}`, {
            params: data,
        });
    },
    sendAgreements: data => axios.post(`${product}/Agreement/Sync`, data),
    sendAgreementsErpCID: data => axios.post(`${product}/Agreement/SyncErpCID`, data),
    syncRating: data => axios.post(`${product}/Rating/Sync`, data),
    getPcaProductCountByTherapyarea: data => axios.get(`${product}/PcaCount`, { params: data }),
    ProductClaim: {
        get: (id) => axios.get(`${product}/ProductClaim/${id}`),
        getByCarePlaceId: (carePlaceId) => axios.get(`${product}/ProductClaim/GetByCarePlaceId/${carePlaceId}`),
        upsert: (data) => axios.post(`${product}/ProductClaim/Upsert`, data),
    },

    ProductGroup: {
        getByMarketAreaCode: (marketAreaCode) => axios.get(`${product}/ProductGroup/GetByMarketAreaCode/${marketAreaCode}`),
    },

    Incontinence: {
        details: (data, header) => axios.get(`Product/api/ProductIncontinence/details`, {
            params: data,
            headers: header ? { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } : {}
        }),
        search: (data, header) => axios.post(`${productSearchApi}/Incontinence`, data,
            header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
    },
    Catheter: {
        details: (data, header) => axios.get(`Product/api/ProductCatheter/details`, {
            params: data,
            headers: header ? { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } : {}
        }),
        search: (data, header) => axios.post(`${productSearchApi}/Catheter`, data,
            header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
        getSimilarCatheterLength: (catheterLength) => axios.get(`Product/api/ProductCatheter/GetSimilarCatheterLength/${catheterLength}`)
    },
    Diabetes: {
        details: (data, header) => axios.get(`Product/api/ProductDiabetes/details`, {
            params: data,
            headers: header ? { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } : {}
        }),
        search: (data, header) => axios.post(`${productSearchApi}/Diabetes`, data,
            header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
    },
    Ostomy: {
        details: (data, header) => axios.get(`Product/api/ProductOstomy/details`, {
            params: data,
            headers: header ? { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } : {}
        }),
        search: (data, header) => axios.post(`${productSearchApi}/Ostomy`, data,
            header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
    },
    Nutrition: {
        details: (data, header) => axios.get(`Product/api/ProductNutrition/details`, {
            params: data,
            headers: header ? { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } : {}
        }),
        search: (data, header) => axios.post(`${productSearchApi}/Nutrition`, data,
            header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
    },
    WoundCare: {
        details: (data, header) => axios.get(`Product/api/ProductWoundCare/details`, {
            params: data,
            headers: header ? { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } : {}
        }),
        search: (data, header) => axios.post(`${productSearchApi}/WoundCare`, data,
            header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
    },
    Other: {
        details: (data, header) => axios.get(`Product/api/ProductOther/details`, {
            params: data,
            headers: header ? { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } : {}
        }),
        search: (data, header) => axios.post(`${productSearchApi}/Other`, data,
            header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
    },
    All: {
        search: (data, header) => axios.post(`${productSearchApi}/All`, data,
            header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
    },
}