import { UserTreeModel } from '@/models/UserTreeModel';
import { UsersService } from '@/services';
import { NODE_TYPES } from "@/common/node-types";
import * as TreeCommons from './tree-commons';

export default {
    namespaced: true,
    state: {
        ...TreeCommons.state,
        entities: [],
    },
    mutations: {
        ...TreeCommons.mutations,
        SET_LAST_ENTITY_SELECTION (state, { UserId }) {
            const user = state.entities.find(x => x.Id === UserId);
            const lastFoundEntity = { type: NODE_TYPES.USER, payload: user };

            if (lastFoundEntity) {
                lastFoundEntity.payload.Selected = true;
                state.selectedTreeItem = lastFoundEntity.payload;
            }
        },
    },
    actions: {
        async searchUsers ({ commit, dispatch }, { SearchTerm, UserId, ActionName }) {
            const response = await UsersService.searchUsers({ SearchTerm, SortBy: "FirstName" });
            const users = response.data.Users.map(x => UserTreeModel.create(x, NODE_TYPES.USER));
            commit("TREE_REMOVE_ENTITY_SELECTION");
            commit("TREE_SET_ENTITIES", users);

            if (!UserId) {
                return;
            }

            const userEntity = users.find(x => x.Id === UserId);
            if (!userEntity) {
                return;
            }
            commit("SET_LAST_ENTITY_SELECTION", { UserId });
            dispatch("actions/selectActionByNodeTypeAndName", { nodeType: NODE_TYPES.USER, actionName: ActionName }, { root: true });
            dispatch("cspActions/selectActionByNodeTypeAndName", { nodeType: NODE_TYPES.USER, actionName: ActionName }, { root: true });
        },
        clearSearch ({ commit }) {
            commit("TREE_SET_ENTITIES", []);
        },
        selectUser ({ commit, dispatch }, node) {
            dispatch("actions/selectFirstActionByNodeType", { nodeType: node.NodeType }, { root: true });
            dispatch("cspActions/selectFirstActionByNodeType", { nodeType: node.NodeType }, { root: true });
            commit("TREE_REMOVE_ENTITY_SELECTION");
            commit("TREE_SET_ENTITY_SELECTION", node);

        },
        clearSelection ({ commit, dispatch }) {
            dispatch("actions/clearSelection", null, { root: true });
            dispatch("cspActions/clearSelection", null, { root: true });
            commit("TREE_REMOVE_ENTITY_SELECTION");
        },
        async fetchMembershipNodes ({ commit, getters }, userId) {
            const response = await UsersService.getUserRelatedNodes(userId);
            const user = getters.getUserById(userId);
            if (user) {
                const clients = UserTreeModel.prepareData(response.data.MembershipNodes, user.Id)
                commit("TREE_SET_CHILD_ENTITIES", { container: user, childEntities: clients });
            }
        },
        async updateUser ({ commit, getters }, user) {
            await UsersService.updateUser(user);
            const userNode = getters.getUserById(user.Id);
            const fullName = `${user.FirstName} ${user.LastName}`;
            commit("SET_ITEM_NAME", { container: userNode, newName: fullName });
        },
    },
    getters: {
        getCount: (state) => {
            return state.entities.length;
        },
        getUserById: (state) => (id) => {
            return state.entities.find(user => user.Id === id);
        }
    },
}
