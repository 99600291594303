import Vue from "vue";

export default class Action {
    constructor() {
        throw new Error('Please use Action.create.');
    }

    static create (data) {
        const action = { ...data };
        action.Selected = false;
        return Object.preventExtensions(Vue.observable(action));
    }
}