export default {
  install (vue) {
    vue.component("ClientForm", () => import("./ClientForm.vue"));
    vue.component("CareRecipientSettings", () => import("./CareRecipientSettings.vue"));
    vue.component("PatientFee", () => import("./PatientFee.vue"));
    vue.component("PrescriptionClientSettings", () => import("./PrescriptionSettings.vue"));
    vue.component("ProductAvailability", () => import("./ProductAvailability.vue"));
    vue.component("ClientRoles", () => import("./AvailableRoles.vue"));
    vue.component("ClientUsers", () => import("./ClientUsers.vue"));
    vue.component("PatientExtraFee", () => import("./PatientExtraFee.vue"));
    vue.component("TherapyAreaSettings", () => import("./TherapyAreaSettings.vue"));
    vue.component("OrderClientSettings", () => import("./OrderSettings.vue"));
    vue.component("AvailableRoles", () => import("./AvailableRoles.vue"));
    vue.component("AuthenticationProviderSettings", () => import("./AuthenticationProviderSettings.vue"));
    vue.component("ClientCustomerNumber", () => import("./CustomerNumber.vue"));
    vue.component("AddNewClientWizard", () => import("./AddNewClientWizard.vue"));
    vue.component("ClientSummary", () => import("./ClientSummary.vue"));
    vue.component("ClientOverview", () => import("./ClientOverview.vue"));
    vue.component("ProductSettings", () => import("./ProductSettings.vue"));
    vue.component("RemoveClient", () => import("./RemoveClient.vue"));
    vue.component("ClientFeatures", () => import("./ClientFeatures.vue"));
  },
};
