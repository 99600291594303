import AxiosFactory from '@/infrastructure/axios-factory';
const loginCtrl = "Identity/api/login";
const axios = AxiosFactory.create();

export default {
    login: (username, password) => {
        const url = "/connect/token";
        const data = new URLSearchParams();
        data.append("username", username);
        data.append("password", password);
        data.append("grant_type", "password");
        data.append("scope", "profile email offline_access roles");
        data.append("client_id", "guide_web");
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }
        return axios.post(url, data, config);
    },
    logout: (refreshToken) => {
        const url = "/connect/revoke";
        const data = new URLSearchParams();
        data.append("token", refreshToken)
        data.append("token_type_hint", "refresh_token")
        data.append("client_id", "guide_web")
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }
        return axios.post(url, data, config);
    },
    changeCarePlace: (carePlaceId) => {
        const url = "/connect/token";
        const data = new URLSearchParams();
        data.append("grant_type", "change_care_place");
        data.append("scope", "profile email offline_access roles");
        data.append("client_id", "guide_web");
        data.append("assertion", carePlaceId);
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }

        return axios.post(url, data, config);
    },
    refreshToken: (refreshToken) => {
        const url = "/connect/token";
        const data = new URLSearchParams();
        data.append("grant_type", "refresh_token");
        data.append("scope", "profile email offline_access roles");
        data.append("client_id", "guide_web");
        data.append("refresh_token", refreshToken);

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }

        return axios.post(url, data, config);
    },
    impersonate: (userId) => {
        const url = "/connect/token";
        const data = new URLSearchParams();
        data.append("grant_type", "impersonate");
        data.append("scope", "profile email offline_access roles");
        data.append("client_id", "guide_web");
        data.append("impersonation_userid", userId);
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }

        return axios.post(url, data, config);
    },
    stopImpersonate: () => {
        const url = "/connect/token";
        const data = new URLSearchParams();
        data.append("grant_type", "stop_impersonate");
        data.append("scope", "profile email offline_access roles");
        data.append("client_id", "guide_web");
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }

        return axios.post(url, data, config);
    },
    loginUsingCard: (siths, grandidsession) => {
        const url = "/connect/grandIdSession";
        const data = new URLSearchParams();
        data.append("siths", siths);
        data.append("grandidsession", grandidsession);
        data.append("grant_type", "card_login");
        data.append("scope", "profile email offline_access roles");
        data.append("client_id", "guide_web");
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }
        return axios.post(url, data, config);
    },
    loginAAD: (bearerToken) => {
        const url = "/connect/aad-login";
        const data = new URLSearchParams();
        data.append("grant_type", "aad_login");
        data.append("scope", "profile email offline_access roles");
        data.append("client_id", "guide_web");
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${bearerToken}`
            }
        }
        return axios.post(url, data, config);
    },
    scopesAAD: () => axios.get("/connect/aad-scopes"),
    twoFactorLogin: (code, username) => {
        const url = "/connect/token";
        const data = new URLSearchParams();
        data.append("assertion", code);
        data.append("username", username);
        data.append("grant_type", "2fa");
        data.append("scope", "profile email offline_access roles");
        data.append("client_id", "guide_web");
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }
        return axios.post(url, data, config);
    },
    passwordResetToken: email => axios.put(`${loginCtrl}/passwordResetToken/${email}`),
    resetPassword: (data) => axios.put(`${loginCtrl}/resetPassword`, data),
    getGrandIdLoginRedirectUrl: (data) => axios.get(`/connect/grandIdLogin`, { params: data }),
    setPassword: (data) => axios.put(`${loginCtrl}/setPassword`, data)
};
