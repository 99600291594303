import AxiosFactory from '@/infrastructure/axios-factory';

const axios = AxiosFactory.create();
const baseUrl = "api/CareRecipient";
const careRecipientBff = "/api/Guide/CareRecipient";
const address = "/api/External/Address/Suggestions"

export default {
    productsearch: data => axios.post(`${careRecipientBff}/ItemNumber/search`, data),
    getAddresses: data => axios.get(`${address}`, {
        params: {
            SearchTerm: data.SearchTerm,
            MarketAreaCode: data.MarketAreaCode,
            PostalCode: data.PostalCode
        },
    }),
    Settings: {
        getById: id => axios.get(`${baseUrl}/Settings/${id}`),
        getByClientId: id => axios.get(`${baseUrl}/Settings/client/${id}`),
        create: data => axios.post(`${baseUrl}/Settings`, data),
        update: data => axios.put(`${baseUrl}/Settings`, data),
        getByCarePlaceId: id => axios.get(`${baseUrl}/Settings/CarePlace/${id}`),
        createCarePlaceSettings: data => axios.post(`${baseUrl}/Settings/CarePlace`, data),
        updateCarePlaceSettings: data => axios.put(`${baseUrl}/Settings/CarePlace`, data)
    },
    CareRecipient: {
        activate: (data) => axios.put(`${careRecipientBff}/Activate`, data),
        create: (data, header) => axios.post(`${baseUrl}/CareRecipient`, data, header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
        getById: data => axios.get(`${baseUrl}/CareRecipient/${data.Id}`),
        getLeanById: id => axios.get(`${baseUrl}/CareRecipientLean/${id}`),
        getFullById: data => axios.get(`${baseUrl}/CareRecipient/full`, { params: data }),
        update: (data, header) => axios.put(`${baseUrl}/CareRecipient`, data, header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
        updateAgeGender: data => axios.put(`${baseUrl}/AgeGender`, data),
        upsertAddress: data => axios.post(`${baseUrl}/Address/Upsert`, data),
        upsertInvoiceAddress: data => axios.post(`${baseUrl}/Address/UpsertInvoiceAddress/`, data),
        search: (data) => axios.post(`${careRecipientBff}/search`, data),
        getBySSN: (dataParams, header) => axios.get(`${baseUrl}/CareRecipient/GetBySSN/${dataParams.ssn}`, header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId } } : {}),
        getFullBySSN: data => axios.get(`${baseUrl}/CareRecipient/GetFullBySSN/${data.ssn}`),
        getIndexSettings: data => axios.get(`${careRecipientBff}/Index/settings`, { params: data }),
        getFromSpar: data => axios.get(`${baseUrl}/CareRecipient/GetFromSpar/${data.ssn}`),
        getByIdWithRegisteredPlaces: (id, header) => axios.get(`${baseUrl}/CareRecipient/GetByIdWithRegisteredPlaces/${id}`,
            header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
        getCareRecipientInformation: (data) => axios.get(`${careRecipientBff}/CareRecipientInformation`, { params: data }),
        getHistoryTabsSettings: data => axios.get(`${careRecipientBff}/HistoryTabsSettings`, { params: data }),
        getBySsnBff: (ssn, header) => axios.get(`${careRecipientBff}/GetBySSN/${ssn}`, header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId } } : {}),
    },
    Organization: {
        copyMove: data => axios.put(`${careRecipientBff}/CareRecipientCarePlace/CopyMove`, data, { headers: { "om-client-id": data.ClientId } }),
        updateStatus: data => axios.put(`${baseUrl}/CareRecipientCarePlace/UpdateStatus`, data)
    },
}