import AxiosFactory from '@/infrastructure/axios-factory';

const axios = AxiosFactory.create();
const baseUrl = '/api/OrganizationUnit';
const ctrlUrl = '/api/OrganizationUnit/Client';
const principalUrl = '/api/Principal/OrganizationUnit';
const productAvailability = "/api/Product/ProductAvailability";
const productSettings = "/api/Product/ProductSettings";

export default {
    createClient: data => axios.post(`${principalUrl}/Client/compound/`, data),
    getById: id => axios.get(`${ctrlUrl}/${id}`),
    create: data => axios.post(ctrlUrl, data),
    update: data => axios.put(ctrlUrl, data),
    getRootItems: () => axios.get(`${ctrlUrl}/node`),
    getClientsWithExpandedNodes: (clientId, careUnitId, carePlaceId) => {
        let url = `${principalUrl}/node/expanded`;
        if (clientId) {
            url = `${url}?ClientId=${clientId}`;
            if (careUnitId) {
                url = `${url}&CareUnitId=${careUnitId}`;
                if (carePlaceId) {
                    url = `${url}&CarePlaceId=${carePlaceId}`;
                }
            }
        }
        return axios.get(url)
    },
    getPatientFeeByClientId: clientId => axios.get(`${ctrlUrl}/PatientFee/${clientId}`),
    createPatientFee: data => axios.post(`${ctrlUrl}/PatientFee/`, data),
    deletePatientFee: (clientId, therapyArea, method, type) => axios.delete(`${ctrlUrl}/PatientFee/${clientId}/${therapyArea}/${method}/${type}`),
    getTherapyAreaSettingsByClientId: clientId => axios.get(`${ctrlUrl}/TherapyAreaSettings/${clientId}`),
    upsertTherapyAreaSettings: data => axios.post(`${ctrlUrl}/TherapyAreaSettings/`, data),
    getRelatedNodes: (data, downward) => axios.get(`${baseUrl}/RelatedNodes`, { params: { Ids: data, Downward: downward } }),
    getWizardRequirements: () => axios.get(`${principalUrl}/Client/wizard/requirements`),
    pdoExists: pdo => axios.get(`${ctrlUrl}/PDO/exists/${pdo}`),
    getProductAvailabilityByClientId: clientId => axios.get(`${productAvailability}/Client/${clientId}`),
    getCustomerNumbersByPdo: (patientDataOwnerId, marketAreaCode) => axios.get(`${principalUrl}/CustomerNumber/pdo`, { params: { PatientDataOwnerId: patientDataOwnerId, MarketAreaCode: marketAreaCode } }),
    upsertCustomerNumbers: data => axios.post(`${principalUrl}/CustomerNumber/upsert`, data),
    createProductAvailability: data => axios.post(`${productAvailability}/Client`, data),
    updateProductAvailability: data => axios.put(`${productAvailability}/Client`, data),
    updateProductSettings: data => axios.put(`${productSettings}`, data),
    createProductSettings: data => axios.post(`${productSettings}`, data),
    getProductSettingsByClientId: clientId => axios.get(`${productSettings}/${clientId}`),
    removeClientById: clientId => axios.delete(`${ctrlUrl}/remove/${clientId}`),
    getDeletedClients: (marketAreaCode) => axios.get(`${ctrlUrl}/deleted`, { params: { MarketAreaCode: marketAreaCode } }),
    deleteClientById: (clientId, hard) => axios.delete(`${ctrlUrl}/${clientId}/${hard}`),
    search: data => axios.get(`${ctrlUrl}/SearchNode`, { params: data }),
    upsertClientFeatures: data => axios.post(`${ctrlUrl}/ClientFeatures/upsert`, data),
    getClientFeaturesByClientId: clientId => axios.get(`${ctrlUrl}/ClientFeatures/${clientId}`),
    getInvoiceArea: marketAreaCode => axios.get(`/api/Principal/IterativePrescription/InvoiceArea/details/${marketAreaCode}`),
    upsertInvoiceArea: data => axios.post(`/api/Principal/IterativePrescription/InvoiceArea/upsert`, data),
}