import AxiosFactory from '@/infrastructure/axios-factory';

const axios = AxiosFactory.create();
const ctrlUrl = '/api/OrganizationUnit/CarePlace';
const principalUrl = '/api/Principal/OrganizationUnit';

export default {
    create: data => axios.post(ctrlUrl, data),
    update: data => axios.put(ctrlUrl, data),
    getById: id => axios.get(`${ctrlUrl}/${id}`),
    getWithCount: careUnitId => axios.get(`${ctrlUrl}/node/${careUnitId}`),
    createCompound: data => axios.post(`${principalUrl}/CarePlace/compound/`, data),
    getWizardRequirements: () => axios.get(`${principalUrl}/CarePlace/wizard/requirements`),
    getCarePlaceOverview: CarePlaceId => axios.get(`${principalUrl}/CarePlace/overview/${CarePlaceId}`),
    getCustomerNumberViewModel: (data) => axios.get(`${principalUrl}/CustomerNumber/CarePlace/vm`, { params: data }),
    upsertCustomerNumbers: (data) => axios.post("/api/OrganizationUnit/CustomerNumber/CarePlace/upsert", data),
    getCareplaceCustomerNumberPairs: clientId => axios.get(`api/OrganizationUnit/CustomerNumber/CarePlace/pairs/${clientId}`),
    sendCarePlaceEmailRequest: (data) => axios.post(`${ctrlUrl}/CarePlaceEmailRequest`, data),
}