import { IMaskDirective } from "vue-imask";

//Extracts first input element inside given html element
export const queryInputElement = (el) => (el.querySelector("input") || el);

export const IMask = {
    bind (el, binding) {
        const inputElement = queryInputElement(el);
        IMaskDirective.bind(inputElement, binding)
    },
    update (el, binding) {
        const inputElement = queryInputElement(el);
        const inputValue = inputElement.value;
        IMaskDirective.update(inputElement, binding);
        if (!!inputElement.maskRef && inputValue !== inputElement.maskRef.value) {
            inputElement.maskRef._listeners["accept"][0]();
        }
    },
    unbind (el) {
        const inputElement = queryInputElement(el);
        IMaskDirective.unbind(inputElement);
    }
}