import Vue from "vue";
import Vuex from "vuex";
import global from "@/store/modules/global.module";
import language from "@/store/modules/language.module";
import alert from "@/store/modules/alert.module";
import account from "@/store/modules/account.module";
import users from "@/store/modules/users.module";
import organizations from "@/store/modules/organizations.module";
import operators from "@/store/modules/operators.module";
import actions from "@/store/modules/actions.module";
import cspActions from "@/store/modules/csp-actions.module";
import formHandler from "@/store/modules/formHandler.module";
import productselection from "@/store/modules/productselection.module";

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    global,
    language,
    alert,
    account,
    users,
    organizations,
    operators,
    actions,
    cspActions,
    formHandler,
    productselection
  },
});

export const useStore = () => store;
export default store;
