import AxiosFactory from '@/infrastructure/axios-factory';

const axios = AxiosFactory.create();
const clientSettingsUrl = "/api/Order/ClientSettings";
const carePlaceSettingsUrl = "/api/Order/CarePlaceSettings";
const patientExtraFeesUrl = "/api/Order/PatientExtraFee";

export default {
    getClientSettingsByClientId: id => axios.get(`${clientSettingsUrl}/${id}`),
    createClientSettings: data => axios.post(clientSettingsUrl, data),
    updateClientSettings: data => axios.put(clientSettingsUrl, data),
    getCarePlaceSettingsByCarePlaceId: id => axios.get(`${carePlaceSettingsUrl}/${id}`),
    createCarePlaceSettings: data => axios.post(carePlaceSettingsUrl, data),
    updateCarePlaceSettings: data => axios.put(carePlaceSettingsUrl, data),
    getPatientExtraFees: id => axios.get(`${patientExtraFeesUrl}/${id}`),
    upsertPatientExtraFees: data => axios.post(`${patientExtraFeesUrl}`, data)
}