import axios from "axios";
import Vue from "vue";
import store from "@/store";
import router from "@/router";
import RefreshTokenManager from "@/infrastructure/refreshtoken-manager";
export const applicationUrl = appConfig.webAppUrl;
export const grpcApiUrl = appConfig.grpcApiUrl;

export default {
  create: () => {
    const axiosClient = axios.create({
      baseURL: grpcApiUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const refreshAndRetryQueue = [];
    let isRefreshing = false;

    axiosClient.interceptors.request.use((request) => {
      const token = store.getters["account/token"];
      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }

      return request;
    });
    axiosClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const originalRequest = error.config;
        const isAuthenticated = store.getters["account/isAuthenticated"];
        if (isAuthenticated && error.response) {
          if (!isRefreshing && error.response.status === 403) {
            Vue.toasted.error("Forbidden", {
              position: "top-right",
              duration: 3000,
              keepOnHover: true,
            });
            return Promise.reject(error.response);
          }
          else if (error.response.status === 401 && !isRefreshing) {
            isRefreshing = true;
            return RefreshTokenManager.execute()
              .then(() => {
                refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                  axiosClient
                    .request(config)
                    .then(response => resolve(response))
                    .catch(err => reject(err))
                });
                refreshAndRetryQueue.length = 0;
                return axiosClient(originalRequest);
              })
              .catch((err) => {
                if (err.status >= 400 && err.status < 500 && err.status != 404 && router.history.current.name !== "Login") {
                  store
                    .dispatch("account/logout")
                    .catch((err) => console.error(err));
                  router.push({ name: "Login" });
                  return;
                }
                return Promise.reject(err);
              })
              .finally(() => {
                isRefreshing = false;
              });
          }
          else if ((error.response.status === 401 || error.response.status === 403) && isRefreshing) {
            return new Promise((resolve, reject) => {
              refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
            });
          }
        }

        if (error.response.status !== 404 && error.response.status !== 401) {
          const message = !error ? "Network error occurred. Please check your internet connection." : error.message;
          Vue.toasted.error(message, {
            position: "top-right",
            duration: 3000,
            keepOnHover: true,
          });
        }

        return Promise.reject(error.response);
      }
    );

    return axiosClient;
  },
};
