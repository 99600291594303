export default {
  install(vue) {
    vue.component("OrderWizard", () => import("./shared/OrderWizard.vue"));
    vue.component("HomeDeliveryPreorder", () => import("./HomeDelivery/Preorder.vue"));
    vue.component("WithdrawalPreorder", () => import("./Withdrawal/Preorder.vue"));
    vue.component("GroupPreorder", () => import("./Group/Preorder.vue"));
    vue.component("NursingHomePreorder", () => import("./NursingHome/Preorder.vue"));
    vue.component("PreliminaryOrder", () => import("./PreliminaryOrder/PreliminaryOrder.vue"));
    vue.component("Confirmation", () => import("./Confirmation/Confirmation.vue"));
    vue.component("OrderOverview", () => import("./OrderOverview.vue"));
    vue.component("Receiver", () => import("./Confirmation/Receiver.vue"));
    vue.component("Client", () => import("./Confirmation/Client.vue"));
    vue.component("DeliveryNotification", () => import("./Confirmation/DeliveryNotification.vue"));
    vue.component("OrderDataTable", () => import("./shared/OrderDataTable.vue"));
  },
};
