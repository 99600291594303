import AxiosFactory from "@/infrastructure/axios-factory";
const axios = AxiosFactory.create();
const usersCtrlUrl = "Identity/api/User";
const operatorsCtrlUrl = "Identity/api/Operator";
const principalUsersUrl = "/api/Principal/User";
const productAvailability = "/api/Product/ProductAvailability";

export default {
  searchOperators: (data) => axios.get(`${operatorsCtrlUrl}/search`, { params: data }),
  createOperator: (data) => axios.post(`${operatorsCtrlUrl}`, data),
  searchUsers: (data) => axios.get(`${principalUsersUrl}/search`, { params: data }),
  searchUsersCUA: (data) => axios.post(`/api/Guide/CUA/User/search`, data),
  createUser: (data) => axios.post(`${usersCtrlUrl}`, data),
  getUserRelatedNodes: (userId) => axios.get(`${principalUsersUrl}/Membership/${userId}`),
  usernameExists: (data) => axios.get(`${usersCtrlUrl}/usernameexists`, { params: data }),
  adUsernameExists: (data) => axios.get(`${usersCtrlUrl}/aduserexists`, { params: data }),
  getById: (userId) => axios.get(`${usersCtrlUrl}/${userId}`),
  updateUser: (data) => axios.put(`${usersCtrlUrl}`, data),
  updateUserStatus: (data) => axios.put(`${usersCtrlUrl}/UpdateStatus`, data),
  updateOperator: (data) => axios.put(`${operatorsCtrlUrl}`, data),
  getOverview: (userId) => axios.get(`${usersCtrlUrl}/overview/${userId}`),
  getProductAvailabilityByUserId: (data) => axios.get(`${productAvailability}/UserOverride`, { params: data }),
  createProductAvailability: (data) => axios.post(`${productAvailability}/User`, data),
  updateProductAvailability: (data) => axios.put(`${productAvailability}/User`, data),
  updateStatus: (data) => axios.put(`${usersCtrlUrl}/updateStatus`, data),
  emailExists: (data) => axios.get(`${usersCtrlUrl}/emailexists/${data}`),
  unlock: (data) => axios.put(`${usersCtrlUrl}/unlock`, data),
  getUsersInClient: (data) => axios.get(`${principalUsersUrl}/clientUsers`, { params: data }),
  sendWelcomeEmail: (data) => axios.post(`${principalUsersUrl}/sendWelcomeEmail`, data),
  getUserCustomerNumbers: (data) => axios.get(`/api/Principal/IterativePrescription/InvoiceArea/GetUserCustomerNumbers`, { params: data }),
  assignInvoiceAreas: (data) => axios.post(`/api/IterativePrescription/InvoiceArea/assign`, data),
  setDefaultCarePlace: (data) => axios.put('/api/Identity/User/setDefaultCarePlace', data),
};
