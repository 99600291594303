import Vue from "vue";
import { TreeNode } from "./TreeNode";
import { NODE_TYPES } from "@/common/node-types";

export class UserTreeModel extends TreeNode {
    constructor() {
        throw new Error('Please use UserTreeModel.create.');
    }

    static create (source, nodeType) {
        source = source || {};
        const destination = new TreeNode(nodeType, source.Roles.length);
        destination.Id = source.Id;
        destination.FirstName = source.FirstName;
        destination.LastName = source.LastName;
        destination.Email = source.Email;
        destination.Name = `${source.FirstName} ${source.LastName}`;
        destination.MarketAreaCode = source.MarketAreaCode;
        destination.$children = (source.Children || []).map(x => {
            x.UserId = source.Id;
            x.TreeNodeType = nodeType;
            x.ClientId = source.ClientId;
            return this.createMembershipNode(x, x.Label);
        });
        destination.Children = [];
        destination.Expanded = destination.Children.length > 0 ? true : false;
        destination.ChildrenLoaded = destination.Children.length !== 0;
        destination.ClientId = source.ClientId;
        return Object.preventExtensions(Vue.observable(destination));
    }

    static createMembershipNode (data, nodeType) {
        data = data || {};
        const membershipNode = new TreeNode(nodeType, data.ChildCount);
        membershipNode.TreeNodeType = data.TreeNodeType;
        membershipNode.ClientId = data.ClientId;
        membershipNode.UserId = data.UserId;
        membershipNode.Id = data.Id;
        membershipNode.Label = data.Label;
        membershipNode.Name = data.Name;
        membershipNode.ParentId = data.ParentId;
        membershipNode.Roles = data.Roles || [];
        membershipNode.$children = (data.Children || []).map(x => {
            x.UserId = data.Id;
            x.TreeNodeType = data.TreeNodeType;
            x.ClientId = data.ClientId;
            return this.createMembershipNode(x, x.Label);
        });
        membershipNode.ChildCount = membershipNode.$children.length;
        membershipNode.RolesCount = data.Roles.length;
        membershipNode.Children = [];
        membershipNode.Expanded = membershipNode.Children.length > 0 ? true : false;
        membershipNode.ChildrenLoaded = membershipNode.Children.length !== 0;
        return Object.preventExtensions(Vue.observable(membershipNode));
    }

    static prepareData (items = [], userId) {
        const careUnits = items.filter(node => node.Label === NODE_TYPES.CARE_UNIT);
        const carePlaces = items.filter(node => node.Label === NODE_TYPES.CARE_PLACE);
        const clients = items
            .filter(node => node.Label === NODE_TYPES.CLIENT)
            .map(client => {
                client.ClientId = client.Id;
                client.UserId = userId;
                client.TreeNodeType = NODE_TYPES.USER;
                client.Children = careUnits.filter(cu => cu.ParentId === client.Id);
                client.Children.map(careUnit => {
                    careUnit.UserId = userId;
                    careUnit.Children = carePlaces.filter(cp => cp.ParentId === careUnit.Id);

                })
                return UserTreeModel.createMembershipNode(client, NODE_TYPES.CLIENT);
            })
        return clients;
    }
}