import AxiosFactory from '@/infrastructure/axios-factory';

const axios = AxiosFactory.create(true);
const baseUrl = '/api/Calendar';

export default {
    get: (data) => axios.post(`${baseUrl}/Calendar/GetCalendar`, data),
    upsert: (data) => axios.post(`${baseUrl}/Calendar/Upsert`, data),
    delete: (id) => axios.delete(`${baseUrl}/Calendar/${id}`),
    updatePeriodDescription: (data) => axios.put(`${baseUrl}/Calendar/PeriodDescription`, data),
}