

export default {
    install (vue) {
        if (!vue.$sessionStorage) {
            const storage = new SessionStorage(window.sessionStorage);
            vue.$sessionStorage = storage;
            vue.prototype.$sessionStorage = storage;
        }
    }
};

class SessionStorage {

    constructor(sessionStorage) {
        this.sessionStorage = sessionStorage
    }

    clear () {
        this.sessionStorage.clear();
    }

    key (index) {
        return this.sessionStorage.key(index);
    }

    getItem (key) {
        return this.sessionStorage.getItem(key);
    }

    removeItem (key) {
        window.dispatchEvent(new CustomEvent("vuestorage", {
            detail: {
                key: key,
                value: null
            }
        }));
        return this.sessionStorage.removeItem(key);
    }

    setItem (key, value) {
        window.dispatchEvent(new CustomEvent("vuestorage", {
            detail: {
                key: key,
                value: value
            }
        }));
        this.sessionStorage.setItem(key, value)
    }

    getFromJson (key) {
        const value = this.sessionStorage.getItem(key);
        return value && JSON.parse(value);
    }

    setAsJson (key, value) {
        if (!value) {
            return;
        }
        this.sessionStorage.setItem(key, JSON.stringify(value));
    }
}