import AxiosFactory from '@/infrastructure/axios-factory';

const axios = AxiosFactory.create();
const deliveryMethodUrl = `/api/Order/DeliveryMethod`

export default {
    upsert: data => axios.post(`${deliveryMethodUrl}/Upsert`, data),
    delete: code => axios.delete(`${deliveryMethodUrl}/${code}`),
    search: data => axios.get(`${deliveryMethodUrl}/search`, { params: data }),
    getByCodeAync: id => axios.get(`${deliveryMethodUrl}/${id}`),
}