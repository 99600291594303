export default {
  install(vue) {
    vue.component("NewOperatorWizard", () => import("./NewOperatorWizard.vue"));
    vue.component("OperatorRoles", () => import("./OperatorRoles.vue"));
    vue.component("OperatorSummary", () => import("./OperatorSummary.vue"));
    vue.component("EditOperator", () => import("./Edit.vue"));
    vue.component("EditOperatorRoles", () => import("./EditRoles.vue"));
    vue.component("OperatorAuthMethods", () => import("./AuthMethods.vue"));
  },
};
