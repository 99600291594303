import mitt from 'mitt'

export default {
    install (vue) {
        if (!vue.$bus) {
            vue.prototype.$bus = mitt();
        }
    }
}

