export default {
  install (vue) {
    vue.component("BasicUserInfo", () => import("./BasicUserInfo.vue"));
    vue.component("AuthMethods", () => import("./AuthMethods.vue"));
    vue.component("RolesList", () => import("./RolesList.vue"));
    vue.component("BasicInfoSummary", () => import("./BasicInfoSummary.vue"));
    vue.component("AuthMethodsSummary", () => import("./AuthMethodsSummary.vue"));
    vue.component("PersonalDetails", () => import("./PersonalDetails.vue"));
    vue.component("AuthenticationDetails", () => import("./AuthenticationDetails.vue"));
    vue.component("UserDetails", () => import("./UserDetails.vue"));
    vue.component("UserOverview", () => import("./UserOverview.vue"));
    vue.component("RolesPreview", () => import("./RolesPreview.vue"));
    vue.component("EditAuthMethods", () => import("./EditAuthMethods.vue"));
  },
};
