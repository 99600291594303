export default {
  install (vue) {
    vue.component("CarePlaceForm", () => import("./CarePlaceForm.vue"));
    vue.component("CarePlaceUsersList", () => import("./CarePlaceUsersList.vue"));
    vue.component("OrderCarePlaceSettings", () => import("./OrderSettings.vue"));
    vue.component("PrescriptionCarePlaceSettings", () => import("./PrescriptionSettings.vue"));
    vue.component("AddNewCarePlaceWizard", () => import("./AddNewCarePlaceWizard.vue"));
    vue.component("WizardOverview", () => import("./WizardOverview.vue"));
    vue.component("CarePlaceOverview", () => import("./CarePlaceOverview.vue"));
    vue.component("CarePlaceCustomerNumber", () => import("./CustomerNumber.vue"));
    vue.component("CareRecipientCarePlaceSettings", () => import("./CareRecipientSettings.vue"));
  },
};
