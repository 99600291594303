import Vue from "vue";

export function registerFilters () {
  Vue.filter("ToUpperCase", ToUpperCase);
}

export function ToUpperCase (value) {
  if (!value) return null;
  return value.toUpperCase();
}
