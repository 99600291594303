import AxiosFactory from "@/infrastructure/axios-factory";

const axios = AxiosFactory.create(true);
const baseUrl = '/api/Identity/AppRegistration';

export default {
    getAll: () => axios.get(`${baseUrl}/all`),
    create: (data) => axios.post(`${baseUrl}`, data),
    delete: (clientId) => axios.delete(`${baseUrl}/${clientId}`),
    getAllPermissions: () => axios.get(`${baseUrl}/permissions/available`),
    upsertPermissions: (data) => axios.post(`${baseUrl}/permissions`, data),
    getAssignedPermissions: (clientId) => axios.get(`${baseUrl}/permissions/clientid/${clientId}`)
}