import AxiosFactory from "@/infrastructure/axios-factory";

const axios = AxiosFactory.create();
const shoppingCartUrl = "/api/ShoppingCart/CarePlace";

export default {
  getCarePlaceActiveSelectedProducts: (CarePlaceId) =>
    axios.get(`${shoppingCartUrl}/${CarePlaceId}`),
  getCarePlaceSelectedProductsHistory: (CarePlaceId) =>
    axios.get(`${shoppingCartUrl}/History/${CarePlaceId}`),
  createCarePlaceSelectedProducts: (data) =>
    axios.post(`${shoppingCartUrl}`, data),
  removeSelectedProduct: (ProductId) =>
    axios.delete(`${shoppingCartUrl}/${ProductId}`),
  removeSelectedProductRange: (ProductIds) => axios.post(`${shoppingCartUrl}/DeleteFlavors`, ProductIds),
};
