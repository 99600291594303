
import { ClientService, CareUnitService, CarePlaceService } from '@/services';
import Client from '@/models/Client';
import CareUnit from '@/models/CareUnit';
import CarePlace from '@/models/CarePlace';
import { NODE_TYPES } from '@/common/node-types';
import * as TreeCommons from "./tree-commons";

export default {
	namespaced: true,
	state: {
		entities: [],
		...TreeCommons.state
	},
	mutations: {
		...TreeCommons.mutations,
		EXPAND_SELECT_NODE_AND_ACTION (state, { ClientId, CareUnitId, CarePlaceId }) {
			const nodesToExpand = [];
			const client = state.entities.find(x => x.Id === ClientId);
			let lastFoundEntity = { type: NODE_TYPES.CLIENT, payload: client };

			if (!!lastFoundEntity && lastFoundEntity.type === NODE_TYPES.CLIENT && !!CareUnitId) {
				const careUnit = lastFoundEntity.payload.Children.find(x => x.Id === CareUnitId);
				nodesToExpand.push(lastFoundEntity.payload);
				lastFoundEntity = careUnit ? { type: NODE_TYPES.CARE_UNIT, payload: careUnit } : lastFoundEntity;
			}

			if (!!lastFoundEntity && lastFoundEntity.type === NODE_TYPES.CARE_UNIT && !!CarePlaceId) {
				const carePlace = lastFoundEntity.payload.Children.find(x => x.Id === CarePlaceId);
				nodesToExpand.push(lastFoundEntity.payload);
				lastFoundEntity = carePlace ? { type: NODE_TYPES.CARE_PLACE, payload: carePlace } : lastFoundEntity;
			}

			nodesToExpand.map(x => {
				x.Expanded = true;
				return x;
			})

			if (lastFoundEntity) {
				lastFoundEntity.payload.Selected = true;
				state.selectedTreeItem = lastFoundEntity.payload;
			}
		},
	},
	actions: {
		async fetchClientsSearch ({ commit, dispatch }, { SearchTerm, ClientId, CareUnitId, CarePlaceId, ActionName }) {
			const params = SearchTerm ? { SearchTerm } : null;
			const response = await ClientService.search(params);
			const clients = response.data.Entities.map(entity => {
				return Client.create(entity);
			})
			commit("TREE_REMOVE_ENTITY_SELECTION");
			commit("TREE_SET_ENTITIES", clients);

			const clientEntity = clients.find(x => x.Id === ClientId);
			if (!clientEntity) {
				return;
			}
			commit("EXPAND_SELECT_NODE_AND_ACTION", { ClientId, CareUnitId, CarePlaceId });
			if (ActionName) {
				let nodeType = undefined;
				if (ClientId) {
					nodeType = NODE_TYPES.CLIENT;
				}
				if (CareUnitId) {
					nodeType = NODE_TYPES.CARE_UNIT;
				}
				if (CarePlaceId) {
					nodeType = NODE_TYPES.CARE_PLACE
				}

				if (nodeType) {
					dispatch("actions/selectActionByNodeTypeAndName", { nodeType, actionName: ActionName }, { root: true });
					dispatch("cspActions/selectActionByNodeTypeAndName", { nodeType, actionName: ActionName }, { root: true });
				}
			}
		},
		async fetchClientsWithCount ({ commit }) {
			const response = await ClientService.getRootItems();
			const clients = response.data.Clients.map(entity => {
				return Client.create(entity);
			})
			commit("TREE_SET_ENTITIES", clients);
		},
		async fetchCareUnitsWithCount ({ commit, getters }, clientId) {
			const response = await CareUnitService.getWithCount(clientId);
			const client = getters.getClientById(clientId);
			if (client) {
				const careUnits = response.data.CareUnits.map(entity => {
					entity.ClientId = clientId;
					return CareUnit.create(entity);
				})
				commit("TREE_SET_CHILD_ENTITIES", { container: client, childEntities: careUnits });
			}
		},
		async fetchCarePlacesWithCount ({ commit, getters }, { clientId, careUnitId }) {
			const response = await CarePlaceService.getWithCount(careUnitId);
			const careUnit = getters.getCareUnitById(clientId, careUnitId)
			if (careUnit) {
				const carePlaces = response.data.CarePlaces.map(entity => {
					entity.ClientId = clientId;
					entity.CareUnitId = careUnitId;
					return CarePlace.create(entity);
				});
				commit("TREE_SET_CHILD_ENTITIES", { container: careUnit, childEntities: carePlaces });
			}
		},
		async fetchClientsWithExpandedNodes ({ commit, dispatch }, { ClientId, CareUnitId, CarePlaceId, ActionName }) {
			if (!ClientId) {
				return;
			}

			const response = await ClientService.getClientsWithExpandedNodes(ClientId || null, CareUnitId || null, CarePlaceId || null);
			const clients = (response.data.Entities || response.data.Clients)
				.map(entity => Client.create(entity));

			commit("TREE_REMOVE_ENTITY_SELECTION");
			commit("TREE_SET_ENTITIES", clients);

			const clientEntity = clients.find(x => x.Id === ClientId);
			if (!clientEntity) {
				return;
			}

			commit("EXPAND_SELECT_NODE_AND_ACTION", { ClientId, CareUnitId, CarePlaceId });
			if (ActionName) {
				let nodeType = undefined;
				if (ClientId) {
					nodeType = NODE_TYPES.CLIENT;
				}
				if (CareUnitId) {
					nodeType = NODE_TYPES.CARE_UNIT;
				}
				if (CarePlaceId) {
					nodeType = NODE_TYPES.CARE_PLACE
				}

				if (nodeType) {
					dispatch("actions/selectActionByNodeTypeAndName", { nodeType, actionName: ActionName }, { root: true });
					dispatch("cspActions/selectActionByNodeTypeAndName", { nodeType, actionName: ActionName }, { root: true });
				}
			}
		},
		async selectEntityAndAction ({ commit, dispatch, getters }, { ClientId, CareUnitId, CarePlaceId, ActionName }) {
			if (!ClientId) {
				return;
			}
			const client = getters.getClientById(ClientId);
			if (!client.ChildrenLoaded && CareUnitId) {
				await dispatch("fetchCareUnitsWithCount", { clientId: ClientId });
			}
			const careUnit = getters.getCareUnitById(ClientId, CareUnitId);
			if (!careUnit.ChildrenLoaded && CarePlaceId) {
				await dispatch("fetchCarePlacesWithCount", { clientId: ClientId, careUnitId: CareUnitId });
			}
			commit("EXPAND_SELECT_NODE_AND_ACTION", { ClientId, CareUnitId, CarePlaceId });
			if (ActionName) {
				let nodeType = undefined;
				if (ClientId) {
					nodeType = NODE_TYPES.CLIENT;
				}
				if (CareUnitId) {
					nodeType = NODE_TYPES.CARE_UNIT;
				}
				if (CarePlaceId) {
					nodeType = NODE_TYPES.CARE_PLACE
				}

				if (nodeType) {
					dispatch("actions/selectActionByNodeTypeAndName", { nodeType, actionName: ActionName }, { root: true });
					dispatch("cspActions/selectActionByNodeTypeAndName", { nodeType, actionName: ActionName }, { root: true });
				}
			}
		},
		async addClient ({ dispatch }, client) {
			const response = await ClientService.create(client)
			client.Id = response.data.Id;
			dispatch("addClientTreeNode", client);
		},
		async removeClient ({ commit, dispatch }, client) {
			const removeClientById = await ClientService.removeClientById(client.Id);
			if (removeClientById.status === 200) {
				dispatch("actions/clearSelection", null, { root: true });
				dispatch("cspActions/clearSelection", null, { root: true });
				commit("REMOVE_CLIENT_FROM_TREE", client.Id);
				commit('TREE_REMOVE_ENTITY_SELECTION');
			}
		},
		addClientTreeNode ({ commit }, client) {
			commit('ADD_TREE_ENTITY', { container: null, item: Client.create(client) });
		},
		async addCareUnit ({ commit, getters }, careUnit) {
			const response = await CareUnitService.create(careUnit);
			careUnit.Id = response.data.Id;
			const client = getters.getClientById(careUnit.ClientId);
			if (client.ChildrenLoaded) {
				commit('ADD_TREE_ENTITY', { container: client, item: CareUnit.create(careUnit) });
			}
			else {
				commit('INCREASE_CHILD_COUNT', client);
			}
		},
		async addCarePlace ({ dispatch }, carePlace) {
			const response = await CarePlaceService.create(carePlace);
			carePlace.Id = response.data.Id;
			dispatch("addCarePlaceTreeNode", carePlace);

		},
		addCarePlaceTreeNode ({ commit, getters }, carePlace) {
			const careUnit = getters.getCareUnitById(carePlace.ClientId, carePlace.CareUnitId)
			if (careUnit.ChildrenLoaded) {
				commit('ADD_TREE_ENTITY', { container: careUnit, item: CarePlace.create(carePlace) });
			}
			else {
				commit('INCREASE_CHILD_COUNT', careUnit);
			}
		},
		async updateClient ({ commit, getters }, client) {
			await ClientService.update(client);
			const clientNode = getters.getClientById(client.Id);
			commit("SET_ITEM_NAME", { container: clientNode, newName: client.Name })
		},
		async updateCareUnit ({ commit, getters }, careUnit) {
			await CareUnitService.update(careUnit);
			const careUnitNode = getters.getCareUnitById(careUnit.ClientId, careUnit.Id);
			commit("SET_ITEM_NAME", { container: careUnitNode, newName: careUnit.Name });
		},
		async updateCarePlace ({ commit, getters }, carePlace) {
			await CarePlaceService.update(carePlace);
			const carePlaceNode = getters.getCarePlaceById(carePlace.ClientId, carePlace.CareUnitId, carePlace.Id);
			commit("SET_ITEM_NAME", { container: carePlaceNode, newName: carePlace.Name });
		},
		applyOrganizationUnitSelection ({ commit, dispatch }, item) {
			dispatch("actions/selectFirstActionByNodeType", { nodeType: item.NodeType }, { root: true });
			dispatch("cspActions/selectFirstActionByNodeType", { nodeType: item.NodeType }, { root: true });
			commit('TREE_REMOVE_ENTITY_SELECTION');
			commit('TREE_SET_ENTITY_SELECTION', item);
		},
		clearSelection ({ commit, dispatch }) {
			dispatch('actions/clearSelection', null, { root: true });
			dispatch("cspActions/clearSelection", null, { root: true });
			commit('TREE_REMOVE_ENTITY_SELECTION');
		},
		clearSearch ({ commit }) {
			commit("TREE_SET_ENTITIES", []);
		},
	},
	getters: {
		getCount: (state) => {
			return state.entities.length
		},
		getClientById: (state) => (id) => {
			return state.entities.find(client => client.Id === id);
		},
		getCareUnitById: (state, getters) => (clientId, id) => {
			const client = getters.getClientById(clientId);
			if (client) {
				return client.Children.find(careUnit => careUnit.Id === id);
			}

			return undefined;
		},
		getCarePlaceById: (state, getters) => (clientId, careUnitId, id) => {
			const careUnit = getters.getCareUnitById(clientId, careUnitId);
			if (careUnit) {
				return careUnit.Children.find(carePlace => carePlace.Id === id);
			}

			return undefined;
		},
	}
}