import AxiosFactory from '@/infrastructure/axios-factory';

const axios = AxiosFactory.create();

const availableRolesUrl = `Identity/api/Role/available`
const rolesUrl = `Identity/api/Role`

export default {
    getRolesByClientId: id => axios.get(`${availableRolesUrl}/${id}`),
    save: (data) => axios.post(availableRolesUrl, data),
    getAvailableOperatorRoles: () => axios.get(`${rolesUrl}/operatorroles`),
    assign: (data) => axios.post(`${rolesUrl}/v2/assign`, data),
    assignV1: (data) => axios.post(`${rolesUrl}/v1/assign`, data),
    getRolesByUserId: userId => axios.get(`${rolesUrl}/getByUserId/${userId}`),
    getEffectiveRoles: userId => axios.get(`${rolesUrl}/effectiveRoles/${userId}`),
    removeCarePlaceRoles: data => axios.delete(`${rolesUrl}`, { data: data }),
    getAssignedRoles: userId => axios.get(`${rolesUrl}/assigned/${userId}`),
    getRolesOverviewByClientId: id => axios.get(`api/Identity/Role/available/${id}`)
}