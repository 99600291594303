import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import { Applications } from "./common/Applications";
import { AccountService } from "@/services";
import AADAuthenticationHandler from "@/infrastructure/aad-authn-handler";
Vue.use(Router);

const getAbility = () => store.getters["account/ability"];
const getCarePlaces = () => store.getters["account/carePlaces"];
const getUser = () => store.state["account"]["user"];
const Guide_tryToForbid = async (permission, next) => {
  const user = getUser();
  try {
    store.dispatch("global/startLoading");
    if (getCarePlaces().length === 0) {
      await store.dispatch("account/loadMembership", user.ImpersonatedUserId ?? user.Id);
    }

    getAbility().can(permission) ? next() : next({ name: "accessDenied" });
  } catch {
    // TODO: Handle error
  } finally {
    store.dispatch("global/stopLoading");
  }
};
const Principal_tryToForbid = (permission, next) =>
  getAbility().can(permission) ? next() : next({ name: "accessDenied" });
const GrandID_getRedirectionUrl = async (siths, next) => {
  const payload = {
    siths: siths,
  };
  try {
    const response = await AccountService.getGrandIdLoginRedirectUrl(payload);
    if (response) {
      window.location.replace(response.data);
      next({ path: response.data });
    }
  } catch (error) {
    store.commit("LOGIN_FAIL", error);
  }
};
const GrandID_getSession = async (grandidsession, siths) => {
  try {
    await store.dispatch("account/loginUsingCard", { siths, grandidsession });
  } catch {
    // TODO: Handle error
  }
};

const routes = [
  {
    path: "/",
    name: "root",
    meta: { noAuth: true },
    component: () => import("./views/HomePage.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("./views/Login.vue"),
    meta: { noAuth: true, hideNavigation: true },
  },
  {
    path: "/2fa",
    name: "2fa",
    component: () => import("./views/Login.vue"),
    meta: { noAuth: true, hideNavigation: true },
  },
  {
    path: "/Organizations",
    name: "organizations",
    component: () => import("@/views/Organizations.vue"),
    redirect: { name: "Clients" },
    beforeEnter: (_, __, next) => {
      Principal_tryToForbid("OrganizationClientRead", next);
    },
    props: (route) => ({ searchText: route.query.search }),
    children: [
      {
        name: "Clients",
        path: "Clients",
        components: {
          sidebar: () => import("@/components/Organizations/ClientsTree.vue"),
          management: () => import("@/components/Organizations/ManagementLanding.vue"),
        },
        props: {
          sidebar: (route) => ({ searchText: route.query.search }),
        },
      },
      {
        name: "ClientsWithParams",
        path: "Clients/CID/:clientId?/CUID/:careUnitId?/CPID/:carePlaceId?/ACT/:actionName?",
        components: {
          sidebar: () => import("@/components/Organizations/ClientsTree.vue"),
          management: () => import("@/components/Organizations/ManagementPane.vue"),
        },
        props: {
          sidebar: (route) => {
            const { clientId, careUnitId, carePlaceId, actionName } = route.params;

            return {
              searchText: route.query.search,
              clientId,
              careUnitId,
              carePlaceId,
              actionName,
            };
          },
        },
      },
      {
        name: "Users",
        path: "Users",
        components: {
          sidebar: () => import("@/components/Organizations/UsersTree.vue"),
          management: () => import("@/components/Organizations/ManagementLanding.vue"),
        },
        props: {
          sidebar: (route) => ({ searchText: route.query.search }),
        },
      },
      {
        name: "UsersWithParams",
        path: "Users/UID/:userId?/ACT/:actionName?",
        components: {
          sidebar: () => import("@/components/Organizations/UsersTree.vue"),
          management: () => import("@/components/Organizations/ManagementPane.vue"),
        },
        props: {
          sidebar: (route) => {
            const { userId, actionName } = route.params;
            return {
              searchText: route.query.search,
              userId: userId ? parseInt(userId) : undefined,
              actionName,
            };
          },
        },
      },
      {
        name: "Operators",
        path: "Operators",
        components: {
          sidebar: () => import("@/components/Organizations/OperatorsTree.vue"),
          management: () => import("@/components/Organizations/ManagementLanding.vue"),
        },
        props: {
          sidebar: (route) => ({ searchText: route.query.search }),
        },
      },
      {
        name: "OperatorsWithParams",
        path: "Operators/OPID/:operatorId?/ACT/:actionName?",
        components: {
          sidebar: () => import("@/components/Organizations/OperatorsTree.vue"),
          management: () => import("@/components/Organizations/ManagementPane.vue"),
        },
        props: {
          sidebar: (route) => {
            const { operatorId, actionName } = route.params;
            return {
              searchText: route.query.search,
              operatorId: operatorId ? parseInt(operatorId) : undefined,
              actionName,
            };
          },
        },
      },
    ],
  },
  {
    path: "/ManageNews",
    component: () => import("@/views/RouterView.vue"),
    children: [
      {
        path: "manageNews",
        name: "InformationMessageMain",
        component: () => import("@/components/ManageNews/InformationMessageMain.vue"),
        beforeEnter: (_, __, next) => Principal_tryToForbid("NewsRead", next),
      },
      {
        path: "Add",
        name: "InformationMessageForm",
        component: () => import("@/components/ManageNews/InformationMessageForm.vue"),
        beforeEnter: (_, __, next) => Principal_tryToForbid("NewsWrite", next),
      },
      {
        name: "InformationMessageEdit",
        path: "Edit/:id",
        component: () => import("@/components/ManageNews/InformationMessageForm.vue"),
        beforeEnter: (_, __, next) => Principal_tryToForbid("NewsWrite", next),
      },
    ],
  },
  {
    path: "/StaticContent",
    component: () => import("@/views/RouterView.vue"),
    children: [
      {
        name: "addStaticContent",
        path: "Add",
        component: () => import("@/components/Utilities/StaticContent/StaticContentForm.vue"),
        beforeEnter: (_, __, next) => Principal_tryToForbid("OrganizationClientRead", next),
      },
      {
        name: "editStaticContent",
        path: "Edit/:id",
        component: () => import("@/components/Utilities/StaticContent/StaticContentForm.vue"),
        beforeEnter: (_, __, next) => Principal_tryToForbid("OrganizationClientRead", next),
      },
    ],
  },
  {
    path: "/Utilities",
    name: "utilities",
    component: () => import("@/views/Utilities.vue"),
    beforeEnter: (_, __, next) => Principal_tryToForbid("OrganizationClientRead", next),
  },
  {
    path: "/CareRecipient",
    component: () => import("@/views/RouterView.vue"),
    children: [
      {
        path: "",
        name: "CareRecipientMain",
        component: () => import("@/components/CareRecipient/Main.vue")
      },
      {
        path: ":careRecipientId",
        component: () => import("@/components/CSP/CareRecipient/CSPCareRecipientDetails.vue"),
        props: (route) => ({ careRecipientId: parseInt(route.params.careRecipientId) }),
        children: [
          {
            path: "",
            component: () => import("@/components/HandleProducts/HandleProducts.vue"),
          },
          {
            path: "HandleProducts",
            name: "CareRecipientHandleProducts",
            component: () => import("@/components/HandleProducts/HandleProducts.vue"),
            meta: {
              breadcrumbName: "HandleProductsTabs.handleProducts",
            },
          },
          {
            path: "OrderHistory",
            name: "CareRecipientOrderHistory",
            component: () => import("@/components/OrderHistory/CareRecipient/OrderHistory.vue"),
            meta: {
              breadcrumbName: "HandleProductsTabs.orderHistory",
            },
          },
          {
            path: "OrderHistory/:customerOrderId",
            name: "CareRecipientOrderHistoryDetails",
            component: () => import("@/components/OrderHistory/CareRecipient/OrderHistoryDetails.vue"),
            props: (route) => ({ customerOrderId: parseInt(route.params.customerOrderId) }),
            meta: {
              breadcrumbName: "HandleProductsTabs.orderHistory",
            },
          },
          {
            path: "SelectedProductHistory",
            name: "CareRecipientSelectedProductHistory",
            meta: {
              breadcrumbName: "HandleProductsTabs.selectedProductHistory",
            },
            component: () => import("@/components/HandleProducts/SelectedProductsHistory.vue"),
          },
          {
            path: "PrescriptionHistory",
            name: "CareRecipientPrescriptionHistory",
            meta: {
              breadcrumbName: "HandleProductsTabs.prescriptionHistory",
            },
            component: () => import("@/components/IterativePrescription/PrescriptionHistory.vue"),
          },
          {
            path: "InvestigationHistory",
            name: "CareRecipientInvestigationHistory",
            meta: {
              breadcrumbName: "HandleProductsTabs.investigationHistory",
            },
            component: () => import("@/components/CareRecipient/InvestigationHistory.vue"),
          },
          {
            path: "Planning",
            name: "CareRecipientPlanning",
            meta: {
              breadcrumbName: "HandleProductsTabs.incontinenceAndCatheter",
            },
            component: () => import("@/components/CareRecipient/Planning/IncontinenceAndCatheter.vue")
          }
        ]
      }
    ]
  },
  // {
  //   path: "/CareRecipient",
  //   name: "careRecipient",
  //   component: () => import("./components/CareRecipient/Main.vue"),
  //   beforeEnter: (_, __, next) => Guide_tryToForbid("CareRecipientRead", next),
  // },
  {
    path: "/TherapyAreaInformation",
    name: "therapyAreaInformationPage",
    component: () => import("@/components/TherapyAreaInformation"),
    beforeEnter: (_, __, next) => {
      // TODO check productSettingByClient Id as in GuideNavigation.vue => method setProductSettingsByClient
      // TODO consider moving to Vuex
      let accessOk = true;
      accessOk ? next(true) : next({ name: "accessDenied" });
    },
  },
  {
    path: "/productSelection",
    component: () => import("@/views/RouterView.vue"),
    children: [
      {
        name: "directProductSelection",
        path: "/ProductSelection/Direct/:careRecipientId/:therapyArea/:isPcaSelection?",
        props: (route) => ({
          careRecipientId: parseInt(route.params.careRecipientId),
          therapyArea: parseInt(route.params.therapyArea),
          isPcaSelection: route.params.isPcaSelection.toString().toLowerCase() === "true",
        }),
        component: () => import("@/components/ProductSelection/DirectProductSelection.vue"),
        // TODO: Check therapy area permissions
        beforeEnter: (_, __, next) => Guide_tryToForbid("ProductPrescriptionRead", next),
      },
      {
        name: "guidedProductSelection",
        path: "/ProductSelection/Guided/:investigationId",
        props: (route) => ({
          investigationId: parseInt(route.params.investigationId),
        }),
        component: () => import("@/components/ProductSelection/GuidedProductSelection.vue"),
        // TODO: Check therapy area permissions
        beforeEnter: (_, __, next) => Guide_tryToForbid("ProductPrescriptionRead", next),
      },
      {
        name: "guidedInvestigation",
        path: "/ProductSelection/Investigation/:careRecipientId/:therapyArea/:editMode?",
        props: (route) => ({
          careRecipientId: parseInt(route.params.careRecipientId),
          therapyArea: parseInt(route.params.therapyArea),
          editMode: route.params.editMode?.toString().toLowerCase() === "true",
        }),
        component: () => import("@/components/ProductSelection/GuidedInvestigation.vue"),
        // TODO: Check therapy area permissions
        beforeEnter: (_, __, next) => Guide_tryToForbid("ProductPrescriptionRead", next),
      },
      {
        name: "prescriptionWizard",
        path: "/PrescriptionWizard/:careRecipientId/:therapyArea/",
        props: (route) => ({
          careRecipientId: parseInt(route.params.careRecipientId),
          therapyArea: parseInt(route.params.therapyArea),
        }),
        component: () => import("@/components/IterativePrescription/Wizard.vue"),
        // TODO: Check therapy area permissions
        beforeEnter: (_, __, next) => Guide_tryToForbid("ProductPrescriptionRead", next),
      },
      {
        path: "/ProductSelection/CarePlace/:CarePlaceId",
        component: () => import("@/components/ProductSelection/CarePlace/MainView.vue"),
        beforeEnter: (_, __, next) => Guide_tryToForbid("CarePlacePurchaseRead", next),
        props: (route) => ({ carePlaceId: route.params.CarePlaceId }),
        children: [
          {
            name: "carePlaceProductSelection",
            path: "",
            meta: {
              breadcrumbName: "carePlace.ProductSelection.products",
            },
            props: (route) => ({ carePlaceId: route.params.CarePlaceId }),
            beforeEnter: (_, __, next) => Guide_tryToForbid("CarePlacePurchaseRead", next),
            component: () => import("@/components/ProductSelection/CarePlace/ProductSelection.vue"),
          },
          {
            name: "carePlaceProductCart",
            path: "Cart",
            meta: {
              breadcrumbName: "carePlace.ProductSelection.selectedProducts",
            },
            props: (route) => ({ carePlaceId: route.params.CarePlaceId }),
            beforeEnter: (_, __, next) => Guide_tryToForbid("CarePlacePurchaseRead", next),
            component: () => import("@/components/ProductSelection/CarePlace/CartView.vue"),
          },
          {
            name: "carePlaceProductCompare",
            path: "CompareProducts",
            meta: {
              breadcrumbName: "carePlace.ProductSelection.compareProducts",
            },
            props: (route) => ({ carePlaceId: route.params.CarePlaceId }),
            beforeEnter: (_, __, next) => Guide_tryToForbid("CarePlacePurchaseRead", next),
            component: () => import("@/components/ProductSelection/CompareProducts.vue"),
          },
        ],
      },
    ],
  },
  {
    name: "ProductGroups",
    path: "/ProductGroups/:careRecipientId/:careRecipientGuid/:therapyArea",
    props: (route) => ({
      careRecipientId: parseInt(route.params.careRecipientId),
      careRecipientGuid: route.params.careRecipientGuid,
      therapyArea: parseInt(route.params.therapyArea),
    }),
    component: () => import("@/components/ProductGroup/ProductGroups.vue"),
    // TODO: Check therapy area permissions
    beforeEnter: (_, __, next) => Guide_tryToForbid("ProductPrescriptionRead", next),
  },
  {
    path: "/CarePlace",
    component: () => import("@/views/CarePlaceGuide.vue"),
    children: [
      {
        path: "",
        name: "orderHistoryTab",
        beforeEnter: (_, __, next) => Guide_tryToForbid("OrderRead", next),
        meta: {
          breadcrumbName: "carePlace.Navigation.orderHistory",
        },
        component: () => import("@/components/CarePlaceGuide/OrderHistory.vue"),
      },
      {
        path: "Overview",
        name: "careplace_overview",
        meta: {
          breadcrumbName: "carePlace.Navigation.carePlaceInformation",
        },
        beforeEnter: async (_, __, next) =>
          Guide_tryToForbid("OrderRead", next) || Guide_tryToForbid("CarePlacePurchaseRead", next),
        component: () => import("@/components/CarePlaceGuide/Overview.vue"),
      },
      {
        path: "SelectedProducts",
        name: "selectedProductsTab",
        beforeEnter: async (_, __, next) =>
          Guide_tryToForbid("OrderRead", next) || Guide_tryToForbid("CarePlacePurchaseRead", next),
        meta: {
          breadcrumbName: "carePlace.Navigation.selectedProducts",
        },
        component: () => import("@/components/CarePlaceGuide/SelectedProducts.vue"),
      },
      {
        path: "SelectionHistory",
        name: "selectionHistoryTab",
        beforeEnter: async (_, __, next) =>
          Guide_tryToForbid("OrderRead", next) || Guide_tryToForbid("CarePlacePurchaseRead", next),
        meta: {
          breadcrumbName: "carePlace.Navigation.selectionHistory",
        },
        component: () => import("@/components/CarePlaceGuide/SelectionHistory.vue"),
      },
      {
        path: "PreliminaryOrders",
        name: "preliminaryOrdersTab",
        beforeEnter: (_, __, next) => Guide_tryToForbid("OrderRead", next),
        meta: {
          breadcrumbName: "carePlace.Navigation.preliminaryOrders",
        },
        component: () => import("@/components/CarePlaceGuide/PreliminaryOrders.vue"),
      },
      {
        path: "HomeDelivery",
        name: "homeDeliveryTab",
        beforeEnter: (_, __, next) => Guide_tryToForbid("OrderWrite", next),
        meta: {
          breadcrumbName: "carePlace.Navigation.homeDelivery",
        },
        component: () => import("@/components/CarePlaceGuide/HomeDelivery.vue"),
      },
      {
        path: "ProductClaims",
        name: "productClaimsTab",
        meta: {
          breadcrumbName: "carePlace.Navigation.productClaims",
        },

        beforeEnter: async (_, __, next) =>
          Guide_tryToForbid("OrderWrite", next) || Guide_tryToForbid("ProductPrescriptionRead", next),
        component: () => import("@/components/CarePlaceGuide/ProductClaims/Claims.vue")
      },
    ],
  },
  {
    path: "/ProductClaim",
    component: () => import("@/views/RouterView.vue"),
    beforeEnter: async (_, __, next) =>
      Guide_tryToForbid("OrderWrite", next) || Guide_tryToForbid("ProductPrescriptionRead", next),
    children: [
      {
        name: "handleProductClaim",
        path: "Add",
        meta: {
          breadcrumbName: "productClaims.claim",
        },
        beforeEnter: async (_, __, next) =>
          Guide_tryToForbid("OrderWrite", next) || Guide_tryToForbid("ProductPrescriptionRead", next),
        component: () => import("@/components/CarePlaceGuide/ProductClaims/HandleProductClaim.vue"),
      },
    ]
  },
  {
    path: "/Order",
    component: () => import("@/views/RouterView.vue"),
    beforeEnter: (_, __, next) => Guide_tryToForbid("OrderRead", next),
    children: [
      {
        path: "HomeDelivery/:careRecipientId/CID/:clientId?/CPID/:carePlaceId?",
        name: "homeDelivery",
        props: (route) => ({
          careRecipientId: parseInt(route.params.careRecipientId),
          clientId: route.params.clientId,
          carePlaceId: route.params.carePlaceId,
        }),
        component: () => import("@/components/Order/HomeDelivery/HomeDelivery.vue"),
        beforeEnter: (_, __, next) => Guide_tryToForbid("OrderWrite", next),
      },
      {
        path: "Withdrawal/CRID/:careRecipientId/CID/:clientId?/CPID/:carePlaceId?",
        name: "withdrawal",
        props: (route) => ({
          careRecipientId: parseInt(route.params.careRecipientId),
          clientId: route.params.clientId,
          carePlaceId: route.params.carePlaceId,
        }),
        component: () => import("@/components/Order/Withdrawal/Withdrawal.vue"),
        beforeEnter: (_, __, next) => Guide_tryToForbid("OrderWrite", next),
      },
      {
        path: "GroupOrder/:preliminaryOrderId?",
        name: "groupOrder",
        props: (route) => {
          const { preliminaryOrderId } = route.params;
          return {
            searchText: route.query.search,
            preliminaryOrderId: preliminaryOrderId ? parseInt(route.params.preliminaryOrderId) : undefined,
          };
        },
        component: () => import("@/components/Order/Group/GroupOrder.vue"),
        beforeEnter: (_, __, next) => Guide_tryToForbid("OrderWrite", next),
      },
      {
        path: "NursingHome/:preliminaryOrderId?",
        name: "nursingHome",
        props: (route) => {
          const { preliminaryOrderId } = route.params;
          return {
            searchText: route.query.search,
            preliminaryOrderId: preliminaryOrderId ? parseInt(route.params.preliminaryOrderId) : undefined,
          };
        },
        component: () => import("@/components/Order/NursingHome/NursingHome.vue"),
        beforeEnter: (_, __, next) => Guide_tryToForbid("OrderWrite", next),
      },
    ],
  },
  {
    path: "/OrderOverview/:customerOrderId",
    name: "orderOverview",
    props: (route) => ({
      customerOrderId: parseInt(route.params.customerOrderId),
    }),
    beforeEnter: (_, __, next) => Guide_tryToForbid("OrderRead", next),
    component: () => import("@/components/OrderHistory/CarePlace/Overview.vue"),
  },
  {
    path: "/MyPages",
    name: "MyPages",
    component: () => import("./views/static/MyPages.vue"),
  },
  {
    path: "/GuideManage",
    name: "GuideManage",
    // TODO reconsider permissions, here for testing only
    component: () => import("@/components/GuideManage/MainView.vue"),
    beforeEnter: (_, __, next) => {
      store.getters["account/sessionCareUnit"]?.Roles?.some(
        (role) =>
          role.Name === "Informer" || role.Name === "RankingHandler" || role.Name === "CustomArticleNumberHandler",
      )
        ? next(true)
        : next({ name: "accessDenied" });
    },
  },
  {
    path: "/GuideAdministration",
    component: () => import("@/components/GuideAdministration/GuideAdminMain.vue"),
    beforeEnter: (_, __, next) => {
      const isCuaAdmin = store.getters["account/sessionCareUnit"]?.Roles?.some((role) => role.Name === "CUAdmin");
      isCuaAdmin ? next(true) : next({ name: "accessDenied" });
    },
    children: [
      {
        path: "",
        name: "GuideAdminUserList",
        meta: {
          breadcrumbName: "Organizations.Users",
        },
        component: () => import("@/components/GuideAdministration/GuideAdminUserList.vue"),
      },
      {
        path: "HandleUser/:userId?",
        name: "GuideAdminHandleUser",
        props: (route) => {
          const { userId } = route.params;
          return {
            userId: userId ? parseInt(userId) : undefined,
          };
        },
        component: () => import("@/components/GuideAdministration/GuideAdminHandleUser/HandleUser.vue"),
      },
      {
        path: "CarePlace",
        name: "GuideAdminHandleCarePlaceEmailRequest",
        meta: {
          breadcrumbName: "common.CarePlace",
        },
        component: () => import("@/components/GuideAdministration/CarePlace/HandleCarePlaceEmailRequest.vue"),
      }
    ],
  },
  {
    path: "/CSP",
    name: "CSPHome",
    redirect: { name: "CSPCareRecipient" },
    component: () => import("@/views/CSPMainView.vue"),
    children: [
      {
        path: "CareRecipient",
        component: () => import("@/views/RouterView.vue"),
        children: [
          {
            path: "",
            component: () => import("@/components/CSP/CareRecipient/CSPMain.vue"),
            props: () => ({ isCSP: true }),
            children: [
              {
                path: "",
                component: () => import("@/components/CareRecipient/Main.vue"),
                name: "CSPCareRecipient",
                props: () => ({ isCSP: true }),
              },
              {
                path: "Anonymize",
                component: () => import("@/components/CSP/CareRecipient/CSPAnonymizeCareRecipient.vue"),
                name: "CSPAnonymizeCareRecipient",
                props: () => ({ isCSP: true }),
              }
            ]
          },
          {
            name: "CSPProductSelection",
            path: "ProductSelection/:careRecipientId/:therapyArea/:isPcaSelection?/:clientId/:carePlaceId/:isCSP",
            component: () => import("@/components/ProductSelection/DirectProductSelection.vue"),
            props: (route) => ({
              careRecipientId: parseInt(route.params.careRecipientId),
              therapyArea: parseInt(route.params.therapyArea),
              isPcaSelection: route.params.isPcaSelection.toString().toLowerCase() === "true",
              selectedClientId: route.params.clientId,
              carePlaceId: route.params.carePlaceId,
              isCSP: route.params.isCSP.toString().toLowerCase() === "true"
            }),
          },
          {
            name: "CSPProductGroups",
            path: "ProductGroups/:careRecipientId/:careRecipientGuid/:therapyArea/:clientId/:carePlaceId/:customerNumber",
            props: (route) => ({
              careRecipientId: parseInt(route.params.careRecipientId),
              careRecipientGuid: route.params.careRecipientGuid,
              therapyArea: parseInt(route.params.therapyArea),
              selectedClientId: route.params.clientId,
              carePlaceId: route.params.carePlaceId,
              customerNumber: route.params.customerNumber
            }),
            component: () => import("@/components/ProductGroup/ProductGroups.vue"),
            // TODO: Check therapy area permissions
            beforeEnter: (_, __, next) => Guide_tryToForbid("ProductPrescriptionRead", next),
          },
          {
            path: "CRID/:careRecipientId/CID/:clientId/CPID/:carePlaceId",
            component: () => import("@/components/CSP/CareRecipient/CSPCareRecipientDetails.vue"),
            props: (route) => ({
              careRecipientId: parseInt(route.params.careRecipientId),
              carePlaceId: route.params.carePlaceId,
              selectedClientId: route.params.clientId,
              isCSP: true
            }),
            children: [
              {
                path: "HandleProducts",
                name: "CSPCareRecipientHandleProducts",
                props: (route) => ({
                  careRecipientId: parseInt(route.params.careRecipientId),
                  crClientId: route.params.clientId,
                  crCarePlaceId: route.params.carePlaceId,
                  isCSP: true
                }),
                meta: {
                  breadcrumbName: "HandleProductsTabs.handleProducts",
                },
                component: () => import("@/components/HandleProducts/HandleProducts.vue"),
              },
              {
                path: "OrderHistory",
                name: "CSPCareRecipientOrderHistory",
                props: (route) => ({
                  careRecipientId: parseInt(route.params.careRecipientId),
                  clientId: route.params.clientId,
                  carePlaceId: route.params.carePlaceId,
                  isCSP: true
                }),
                meta: {
                  breadcrumbName: "HandleProductsTabs.orderHistory",
                },
                component: () => import("@/components/OrderHistory/CareRecipient/OrderHistory.vue"),
              },
              {
                path: "OrderHistory/:customerOrderId",
                name: "CSPCareRecipientOrderHistoryDetails",
                component: () => import("@/components/OrderHistory/CareRecipient/OrderHistoryDetails.vue"),
                props: (route) => ({
                  customerOrderId: parseInt(route.params.customerOrderId),
                  careRecipientId: parseInt(route.params.careRecipientId),
                  selectedClientId: route.params.clientId,
                  carePlaceId: route.params.carePlaceId,
                  isCSP: true
                }),
                meta: {
                  breadcrumbName: "HandleProductsTabs.orderHistory",
                },
              },
              {
                path: "SelectedProductHistory",
                props: (route) => ({
                  careRecipientId: parseInt(route.params.careRecipientId),
                  selectedClientId: route.params.clientId,
                  carePlaceId: route.params.carePlaceId,
                  isCSP: true
                }),
                meta: {
                  breadcrumbName: "HandleProductsTabs.selectedProductHistory",
                },
                name: "CSPCareRecipientSelectedProductHistory",
                component: () => import("@/components/HandleProducts/SelectedProductsHistory.vue"),
              },
              {
                path: "PrescriptionHistory",
                name: "CSPCareRecipientPrescriptionHistory",
                props: (route) => ({
                  careRecipientId: parseInt(route.params.careRecipientId),
                  selectedClientId: route.params.clientId,
                  carePlaceId: route.params.carePlaceId,
                  isCSP: true
                }),
                meta: {
                  breadcrumbName: "HandleProductsTabs.prescriptionHistory",
                },
                component: () => import("@/components/IterativePrescription/PrescriptionHistory.vue"),
              },
              {
                path: "InvestigationHistory",
                name: "CSPCareRecipientInvestigationHistory",
                props: (route) => ({
                  careRecipientId: parseInt(route.params.careRecipientId),
                  clientId: route.params.clientId,
                  carePlaceId: route.params.carePlaceId,
                  isCSP: true
                }),
                meta: {
                  breadcrumbName: "HandleProductsTabs.investigationHistory",
                },
                component: () => import("@/components/CareRecipient/InvestigationHistory.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "Organizations",
        name: "CSPOrganizations",
        redirect: { name: "CSPClients" },
        component: () => import("@/components/CSP/CSPOrganizations.vue"),
        props: (route) => ({ searchText: route.query.search }),
        children: [
          {
            path: "Clients",
            name: "CSPClients",
            components: {
              cspSidebar: () => import("@/components/CSP/Organizations/CSPClientsTree.vue"),
              cspMainView: () => import("@/components/CSP/Organizations/Landing.vue"),
            },
            props: {
              cspSidebar: (route) => ({ searchText: route.query.search }),
            },
          },
          {
            path: "Clients/CID/:clientId?/CUID/:careUnitId?/CPID/:carePlaceId?/ACT/:actionName?",
            name: "CSPClientsWithParams",
            components: {
              cspSidebar: () => import("@/components/CSP/Organizations/CSPClientsTree.vue"),
              cspMainView: () => import("@/components/CSP/Organizations/DetailsView.vue"),
            },
            props: {
              cspSidebar: (route) => {
                const { clientId, careUnitId, carePlaceId, actionName } = route.params;

                return {
                  searchText: route.query.search,
                  clientId,
                  careUnitId,
                  carePlaceId,
                  actionName,
                };
              },
            },
          },
          {
            path: "Users",
            name: "CSPUsers",
            components: {
              cspSidebar: () => import("@/components/CSP/Organizations/CSPUsersTree.vue"),
              cspMainView: () => import("@/components/CSP/Organizations/Landing.vue"),
            },
            props: {
              cspSidebar: (route) => ({ searchText: route.query.search }),
            },
          },
          {
            path: "Users/UID/:userId?/ACT/:actionName?",
            name: "CSPUsersWithParams",
            components: {
              cspSidebar: () => import("@/components/CSP/Organizations/CSPUsersTree.vue"),
              cspMainView: () => import("@/components/CSP/Organizations/DetailsView.vue"),
            },
            props: {
              cspSidebar: (route) => {
                const { userId, actionName } = route.params;
                return {
                  searchText: route.query.search,
                  userId: userId ? parseInt(userId) : undefined,
                  actionName,
                };
              },
            },
          },
          {
            path: "Operators",
            name: "CSPOperators",
            components: {
              cspSidebar: () => import("@/components/CSP/Organizations/CSPOperatorsTree.vue"),
              cspMainView: () => import("@/components/CSP/Organizations/Landing.vue"),
            },
            props: {
              cspSidebar: (route) => ({ searchText: route.query.search }),
            },
          },
          {
            path: "Operators/OPID/:operatorId?/ACT/:actionName?",
            name: "CSPOperatorsWithParams",
            components: {
              cspSidebar: () => import("@/components/CSP/Organizations/CSPOperatorsTree.vue"),
              cspMainView: () => import("@/components/CSP/Organizations/DetailsView.vue"),
            },
            props: {
              cspSidebar: (route) => {
                const { operatorId, actionName } = route.params;
                return {
                  searchText: route.query.search,
                  operatorId: operatorId ? parseInt(operatorId) : undefined,
                  actionName,
                };
              },
            },
          },
        ],
      },
      {
        path: "ManageInformation",
        component: () => import("@/views/CSPMainView.vue"),
        children: [
          {
            path: "",
            name: "CSPManageInformationList",
            component: () => import("@/components/ManageNews/InformationMessageMain.vue"),
            props: {
              isCSP: true
            }
          },
          {
            path: "Add",
            name: "CSPManageInformationAdd",
            component: () => import("@/components/ManageNews/InformationMessageForm.vue"),
            props: {
              isCSP: true
            }
          },
          {
            path: "Edit/:id",
            name: "CSPManageInformationEdit",
            component: () => import("@/components/ManageNews/InformationMessageForm.vue"),
            props: {
              isCSP: true
            }
          },
        ],
      },
      {
        path: "Utilities",
        name: "CSPUtilities",
        redirect: { name: "CSPItemReplacement" },
        component: () => import("@/components/CSP/CSPUtilities.vue"),
        children: [
          {
            name: "CSPItemReplacement",
            path: "ItemReplacement",
            component: () => import("@/components/CSP/Utilities/CSPItemReplacement.vue"),
          },
          {
            name: "CSPItemAgreement",
            path: "ItemAgreement",
            component: () => import("@/components/CSP/Utilities/CSPItemAgreement.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/AccessDenied",
    name: "accessDenied",
    component: () => import("@/views/static/AccessDenied.vue"),
    meta: { hideNavigation: false },
  },
  {
    path: "/Disclaimer",
    name: "Disclaimer",
    meta: { noAuth: true },
    component: () => import("@/views/Disclaimer.vue"),
  },
  {
    path: "/ContactForm",
    name: "ContactForm",
    meta: { noAuth: true },
    component: () => import("@/views/ContactForm.vue"),
  },
  {
    path: "/Help",
    name: "HelpPage",
    meta: { noAuth: true },
    component: () => import("@/views/HelpPage.vue"),
  },
  {
    path: "/SelectCarePlace",
    name: "selectCarePlace",
    component: () => import("@/views/SelectCarePlace.vue"),
    beforeEnter: (_, __, next) => (store.getters["account/isAuthenticated"] ? next() : next({ name: "accessDenied" })),
    meta: { hideNavigation: true },
  },
  {
    path: "/Inquiry",
    name: "inquiry",
    component: () => import("@/views/static/Inquiry.vue"),
  },
  {
    path: "/OneMedProductRange",
    name: "productCatalog",
    component: () => import("@/components/ProductSelection/ProductCatalog.vue"),
    beforeEnter: (_, __, next) => Guide_tryToForbid("ProductPrescriptionRead", next),
  },
  {
    path: "/SithsLogin",
    name: "sithsLogin",
    beforeEnter: (_, __, next) => GrandID_getRedirectionUrl(true, next),
  },
  {
    path: "/VrkLogin",
    name: "vrkLogin",
    beforeEnter: (_, __, next) => GrandID_getRedirectionUrl(false, next),
  },
  {
    path: "/AAD",
    name: "aadLogin",
    beforeEnter: async (_, __, next) => {
      const response = await AADAuthenticationHandler.login();
      if (!response) {
        return next('root')
      }

      await store.dispatch("account/loginAAD", response.accessToken);
      next();
    },
    meta: { hideNavigation: true },
  },
  {
    path: "/SithsCallback",
    name: "sithsCallback",
    beforeEnter: (to, __, next) => GrandID_getSession(to.query.grandidsession, true, next),
  },
  {
    path: "/VrkCallback",
    name: "vrkCallback",
    beforeEnter: (to, __, next) => GrandID_getSession(to.query.grandidsession, false, next),
  },
  {
    path: "*",
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = new Router({
  mode: "history",
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  if (
    to.name === "Login" ||
    to.name === "sithsLogin" ||
    to.name === "vrkLogin" ||
    to.name === "sithsCallback" ||
    to.name === "vrkCallback" ||
    to.name === "aadLogin" ||
    to.name === "aadCallback"
  ) {
    // skip all checks
    next();
    return;
  }
  const noAuth = to.matched.some((record) => record.meta.noAuth);
  const user = getUser();

  if (user && user.Application) {
    try {
      store.dispatch("global/startLoading");

      if (user.Application === Applications.Guide) {
        if (getCarePlaces().length === 0) {
          await store.dispatch("account/loadMembership", user.ImpersonatedUserId ?? user.Id);
        }
      }

      if (to.name === "root") {
        // It's operator
        if (user.Application === Applications.Principal) {
          return next({ name: "organizations" });
        }

        // It's CSP
        if (user.Application === Applications.CSP) {
          return next({ name: "CSPCareRecipient" });
        }

        // It's guide user

        if (user.Application === Applications.Guide) {
          if (getCarePlaces().length === 0) {
            return next({ name: "accessDenied" });
          }

          let carePlace = null;
          if (getCarePlaces().length === 1) {
            carePlace = getCarePlaces()[0];
          } else {
            const defaultCarePlaceId = store.getters["account/defaultCarePlaceId"];
            carePlace = getCarePlaces().find((x) => x.Id === defaultCarePlaceId);
          }
          if (carePlace) {
            await store.dispatch("account/selectCarePlace", carePlace.Id);
            if (getAbility().can("CareRecipientRead")) {
              return next({ name: "CareRecipientMain" });
            }
            if (getAbility().can("CarePlacePurchaseRead")) {
              return next({ name: "careplace_overview" });
            }
          }

          return next({ name: "selectCarePlace" });
        }
      }
    } catch {
      // TODO: Handle error
    } finally {
      store.dispatch("global/stopLoading");
    }
  }

  if (!noAuth && (!user || !user.Application)) {
    return next({ name: "Login" });
  }

  next();
});

export const useRouter = () => router;
export default router;
