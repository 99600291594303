<template>
  <div class="app-wrapper">
    <!-- the 'root' route condition should be removed when we implement static index.html -->
    <Maintenance
      v-if="isMaintenance && $route.name !== 'root'"
      :message="maintenenceMessage"
      @handleMaintenance="handleMaintenance"
    />
    <v-dialog class="dialog-position" />
    <SessionExpiration />
    <om-spinner-block v-if="isLoading" />
    <div id="application" v-else>
      <TopBar v-if="!$route.meta.hideNavigation" />
      <div class="app-container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { StaticContent } from "@/services";

export default {
  components: {
    Maintenance: () => import("@/views/static/Maintenance.vue"),
    TopBar: () => import("@/components/shared/TopBar.vue"),
    SessionExpiration: () => import("@/components/shared/SessionExpiration.vue"),
  },
  data() {
    return {
      isMaintenance: false,
      maintenenceMessage: "",
      mmCokieName: "guide-mm",
    };
  },
  computed: {
    ...mapGetters("global", ["isLoading"]),
    ...mapState("language", ["language"]),
    ...mapState("alert", ["showDialog", "showNotification", "message", "title", "type"]),
  },
  created() {
    const cookieValue = this.$cookies.get(this.mmCokieName);
    if (!cookieValue) {
      this.getMaintenanceMessage();
    } else {
      this.isMaintenance = cookieValue.Show;
      this.maintenenceMessage = cookieValue.Msg;
      if ((this.maintenenceMessage == null || this.maintenenceMessage.length == 0) || (new Date(cookieValue.ValidUntil) < new Date())) {
        this.getMaintenanceMessage(cookieValue.Version);
      }
    }
  },
  mounted() {
    this.handleCookieScript();
  },
  methods: {
    ...mapActions("alert", ["hideDialog", "clearNotification"]),
    handleCookieScript() {
      const selectedLanguage = localStorage.getItem("language") || navigator.language.substring(0, 2) || "EN";
      const theHeadTag = document.getElementsByTagName("head")[0];

      const scriptTag = document.createElement("script");
      scriptTag.setAttribute("id", "CookieConsent");
      scriptTag.setAttribute("src", "https://policy.app.cookieinformation.com/uc.js");
      scriptTag.setAttribute("data-culture", selectedLanguage.toLocaleUpperCase());
      scriptTag.setAttribute("type", "text/javascript");

      theHeadTag.append(scriptTag);
    },
    handleMaintenance(bool) {
      this.isMaintenance = bool;
      const cookieValue = this.$cookies.get(this.mmCokieName);
      if (cookieValue) {
        cookieValue.Show = bool;
        this.$cookies.set(this.mmCokieName, cookieValue, "1d");
      }
    },
    async getMaintenanceMessage(version) {
      try {
        const result = await StaticContent.getMaintenance(this.language);
        if (result.data) {
          this.maintenenceMessage = result.data.Message;
        }
        const cookieValue = {
          Version: result.data.Version,
          ValidUntil: new Date().setHours(new Date().getHours() + 1),
          Show: version != result.data.Version ? true : false,
          Msg: result.data.Message,
        };
        
        this.isMaintenance = cookieValue.Show;
        this.$cookies.set(this.mmCokieName, cookieValue, "1d");
      } catch {
        this.isMaintenance = false;
        const cookieValue = {
          Version: null,
          ValidUntil: new Date().setHours(new Date().getHours() + 1),
          Show: false,
        };
        this.$cookies.set(this.mmCokieName, cookieValue, "1d");
      }
    },
  },
  watch: {
    showDialog(value) {
      if (value === true) {
        this.$modal.show("dialog", {
          title: this.title,
          text: this.message,
          buttons: [
            {
              title: this.$t("common.ok"),
              class: "primary normal flex-none",
              handler: () => {
                this.$modal.hide("dialog");
              },
            },
          ],
        });
        this.hideDialog();
      }
    },
    showNotification(value) {
      if (value === true) {
        if (this.type === "success") {
          this.$toasted.success(this.message, {
            theme: "bubble",
          });
        } else if (this.type === "error") {
          this.$toasted.error(this.message, {
            theme: "bubble",
          });
        } else {
          this.$toasted.show(this.message, {
            theme: "bubble",
          });
        }
        this.clearNotification();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/theme/_variables.module.scss";
@import "@/theme/_flex.scss";
@import "@/theme/_common.scss";

.app-wrapper {
  height: 100%;
  overflow-y: auto;
  @include flex-column;
}

.app-container {
  @include flex-column;
  flex-grow: 1;
  min-height: 0;
  @include space-bottom(1);
  margin-bottom: 0;
  div {
    min-height: 0;
  }
}

[v-cloak] {
  display: none;
}

.hide {
  display: none;
}

.header-status {
  background-color: #b94848;
  padding: 20px;
  color: white;
  &__x {
    font-size: 20px;
    align-self: center;
    justify-content: flex-end;
    &:hover {
      cursor: pointer;
    }
  }
}

.width {
  justify-content: space-between;
}

.align {
  flex-grow: 1;
}

.close {
  align-self: center;
}

#application {
  display: flex;
  height: 100vh;
  overflow-y: auto;
  flex-direction: column;
}

.dialog-position {
  z-index: 1000 !important;
}
</style>