import Action from "@/models/Action";
import clientActions from "@/components/CSP/Organizations/actions/client.json";
import careUnitsActions from "@/components/CSP/Organizations/actions/careunit.json";
import carePlaceActions from "@/components/CSP/Organizations/actions/careplace.json";
import userActions from "@/components/CSP/Organizations/actions/user.json";
import operatorActions from "@/components/CSP/Organizations/actions/operator.json";

export default {
  namespaced: true,
  state: {
    selectedAction: undefined,
    actions: {
      Client: clientActions.actions.map((x) => Action.create(x)),
      CareUnit: careUnitsActions.actions.map((x) => Action.create(x)),
      CarePlace: carePlaceActions.actions.map((x) => Action.create(x)),
      User: userActions.actions.map((x) => Action.create(x)),
      Operator: operatorActions.actions.map((x) => Action.create(x)),
    },
  },
  actions: {
    selectActionByNodeTypeAndName ({ dispatch, getters }, { nodeType, actionName }) {
      const actions = getters.getActions(nodeType);
      const action = actions.find((x) => x.ComponentName === actionName);
      if (action) {
        dispatch("selectAction", action);
      }
    },
    selectFirstActionByNodeType ({ dispatch, getters }, { nodeType }) {
      const actions = getters.getActions(nodeType);
      const firstAction = actions.find((x) => x.ComponentName.toLowerCase().includes("overview"));
      if (firstAction) {
        dispatch("selectAction", firstAction);
      }
    },
    selectAction ({ commit }, item) {
      commit("CLEAR_SELECTION");
      commit("SET_SELECTION", item);
    },
    clearSelection ({ commit }) {
      commit("CLEAR_SELECTION");
    },
  },
  mutations: {
    SET_SELECTION (state, newVal) {
      state.selectedAction = newVal;
      state.selectedAction.Selected = true;
    },
    CLEAR_SELECTION (state) {
      if (state.selectedAction) {
        state.selectedAction.Selected = false;
        state.selectedAction = null;
      }
    },
  },
  getters: {
    getActions: (state) => (nodeType) => {
      const normalizedNodeType = nodeType.replace("_", "").toLowerCase();
      for (const key in state.actions) {
        if (key.toLowerCase() === normalizedNodeType) {
          return state.actions[key];
        }
      }
    },
  },
};
