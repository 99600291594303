import AxiosFactory from "@/infrastructure/axios-factory";

const axios = AxiosFactory.create();

const iterativePrescription = "IterativePrescription/api/";
const orderUrl = "/api/Order";
const preorderUrl = "/api/Guide/Preorder";
const externalApiUrl = "/api/External";

export default {
  iterativePrescription: (dataParams) =>
    axios.get(`${iterativePrescription}GetValidated`, {
      params: dataParams,
    }),
  getDeliveryPoints: (data) => axios.get(`${externalApiUrl}/Shipment`, { params: data }),
  HomeDeliveryPreorder: {
    get: (careRecipientId, header) => axios.get(`${preorderUrl}/HomeDelivery/${careRecipientId}`,
      header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
  },
  WithdrawalPreorder: {
    get: (careRecipientId, header) => axios.get(`${preorderUrl}/Withdrawal/${careRecipientId}`,
      header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
  },
  GroupPreorder: {
    get: (data) => axios.get(`${preorderUrl}/Group/${data.CarePlaceId}`),
  },
  NursingHomePreorder: {
    get: () => axios.get(`${preorderUrl}/NursingHome`),
  },
  PreliminaryOrder: {
    upsert: (data) => axios.post(`${orderUrl}/PreliminaryOrder/Upsert`, data),
    get: (data) => axios.post(`${orderUrl}/PreliminaryOrder/SearchByCarePlace`, data),
    updateStatus: (data) => axios.put(`${orderUrl}/PreliminaryOrder/UpdateFavorite`, data),
    getById: id => axios.get(`${orderUrl}/PreliminaryOrder/${id}`),
    delete: id => axios.delete(`${orderUrl}/PreliminaryOrder/${id}`),
  },
  Order: {
    createHomeDelivery: (data, header) => axios.post(`${orderUrl}/HomeDelivery`, data, header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
    createWithdrawal: (data, header) => axios.post(`${orderUrl}/Withdrawal`, data, header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
    createGroupOrder: (data) => axios.post(`${orderUrl}/Group`, data),
    createNursingHome: (data) => axios.post(`${orderUrl}/NursingHome`, data),
    confirm: (data, header) => axios.post(`${orderUrl}/Order/Confirm`, data, header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
    getByCareRecipient: (data, header) => axios.post(`${orderUrl}/Order/SearchByCareRecipient`, data, header ? { headers: { "om-care-place-id": header.CarePlaceId, "om-client-id": header.ClientId, "om-mac": header.MarketAreaCode } } : {}),
    getByCarePlace: (data) => axios.post(`${orderUrl}/Order/SearchByCarePlace`, data),
    getOverview: (customerOrderId, excludeErpData = false, headers) => axios.get(`${orderUrl}/Order/Overview`, {
      params: {
        CustomerOrderId: customerOrderId,
        ExcludeErpData: excludeErpData
      },
      headers: headers ? {
        "om-care-place-id": headers.CarePlaceId,
        "om-client-id": headers.ClientId,
        "om-mac": headers.MarketAreaCode
      } : {}
    }),
    getOverviewForCareRecipient: (customerOrderId, careRecipientId, headers) => axios.get(`${orderUrl}/Order/OverviewForCareRecipient`, {
      params: {
        CustomerOrderId: customerOrderId,
        CareRecipientId: careRecipientId
      },
      headers: headers ? {
        "om-care-place-id": headers.CarePlaceId,
        "om-client-id": headers.ClientId,
        "om-mac": headers.MarketAreaCode
      } : {}
    }),
    cancel: (data) => axios.put(`${orderUrl}/Order/Cancel`, data),
    getLastWithdrawalFlavors: (careRecipientId, productNumber) => axios.get(`${orderUrl}/Order/LastWithdrawalFlavors/${careRecipientId}/${productNumber}`),
    getSumAndParcelLinkAsync: (customerOrderId, customerNumber, erpOrderId) => axios.get(`${orderUrl}/Order/SumAndParcelLink/${customerOrderId}/${customerNumber}/${erpOrderId}`),
    getErpHistory: (careRecipientId, headers) => axios.get(`${orderUrl}/Order/OrderHistory/${careRecipientId}`, {
      headers: headers ? {
        "om-care-place-id": headers.CarePlaceId,
        "om-client-id": headers.ClientId,
        "om-mac": headers.MarketAreaCode
      } : {}
    }),
    sendSampleOrderEmailRequest: (data) => axios.post(`${orderUrl}/Order/SampleOrderEmailRequest`, data),
  },
};
