import CareUnitForm from "./CareUnitForm.vue";

export default {
  install(vue) {
    vue.component("CareUnitForm", CareUnitForm);
    vue.component("CareUnitRoles", () => import("./CareUnitRoles.vue"));
    vue.component("CareUnitUsers", () => import("./CareUnitUsers.vue"));
    vue.component("CareUnitOverview", () => import("./CareUnitOverview.vue"));
  },
};
