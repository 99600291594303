import AxiosFactory from '@/infrastructure/axios-factory';

const axios = AxiosFactory.create();
const informationMessageUrl = `/api/InformationMessage`

export default {
    create: data => axios.post(informationMessageUrl, data),
    delete: id => axios.delete(`${informationMessageUrl}/${id}`),
    search: data => axios.post(`${informationMessageUrl}/Search`, data),
    getById: id => axios.get(`${informationMessageUrl}/${id}`),
    update: data => axios.put(`${informationMessageUrl}`, data),
    uploadFile: data => axios.post(`${informationMessageUrl}/Upload`, data),
    downloadFile: path => axios.get(`${informationMessageUrl}/Download/${path}`),
    removeDocument: id => axios.delete(`${informationMessageUrl}/removeDocument/${id}`),
    getMyPagesDataByPdo: patientDataOwnerId => axios.get(`${informationMessageUrl}/MyPages/${patientDataOwnerId}`)
}