import AxiosFactory from '@/infrastructure/axios-factory';

const axios = AxiosFactory.create();

export default {

	getAllClients() {
		return axios.get('/api/Client/root');
		// return axios.get('http://dev-gui01-srv.dmz.local:5002/api/v1/OrganizationUnit/tree');
	},
	getCareUnits(clientId) {
		return axios.get(`/mock/careunits.json?clientid=${clientId}`);
	},
	getCarePlaces(careUnitId) {
		return axios.get(`/mock/careplaces.json?careUnitId=${careUnitId}`);
	},
	getUsers(carePlaceId) {
		return axios.get(`/mock/users.json?carePlaceId=${carePlaceId}`);
	},
	getOrganizationUnitExpanded(clientId, careUnitId, carePlaceId) {
		return axios.get(`/mock/organization-units-expanded.json?clientId=${clientId}&careUnitId=${careUnitId}&carePlaceId=${carePlaceId}`);
	},
	getActions(nodeType) {
		return axios.get(`mock/actions/${nodeType}.json`);
	}
}