import { NODE_TYPES } from '@/common/node-types';
import { TreeNode } from './TreeNode';
import Vue from "vue";
import CareUnit from '@/models/CareUnit.js';
import { Guid } from '@/common/Guid';

export default class Client extends TreeNode {
    constructor() {
        throw new Error('Please use Client.create.');
    }

    static create (data) {
        data = data || {};
        const treeNode = new TreeNode(NODE_TYPES.CLIENT, data.ChildCount);
        treeNode.Id = data.Id || Guid.Empty;
        treeNode.Name = data.Name;
        treeNode.ERPCustomerIdentifier = data.ERPCustomerIdentifier ?? 'TOP_';
        treeNode.MarketAreaCode = data.MarketAreaCode;
        treeNode.PatientDataOwnerId = data.PatientDataOwnerId;
        treeNode.$children = (data.Children || []).map(x => {
            x.ClientId = treeNode.Id;
            return CareUnit.create(x);
        });
        treeNode.Children = treeNode.$children;
        treeNode.Expanded = treeNode.$children.length > 0 ? true : false;
        treeNode.ChildrenLoaded = treeNode.Children.length !== 0 || treeNode.Children.length === treeNode.ChildCount;
        return Object.preventExtensions(Vue.observable(treeNode));
    }
}