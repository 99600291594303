export default {
  install (vue) {
    vue.component("om-select", () => import("./OMBasicSelect.vue"));
    vue.component("om-button", () => import("./OMButton.vue"));
    vue.component("om-checkbox", () => import("./OMCheckBox.vue"));
    vue.component("om-datatable", () => import("./OMDataTable.vue"));
    vue.component("om-datatable-flex", () => import("./OMDataTableFlex.vue"));
    vue.component("om-datatable-grid", () => import("./OMDataTableGrid.vue"));
    vue.component("om-description", () => import("./OMDescription.vue"));
    vue.component("om-groupbox", () => import("./OMGroupBox.vue"));
    vue.component("om-imagebutton", () => import("./OMImageButton.vue"));
    vue.component("om-label", () => import("./OMLabel.vue"));
    vue.component("om-linetextinput", () => import("./OMLineTextInput.vue"));
    vue.component("om-modal-handler", () => import("./OMModalHandler.vue"));
    vue.component("om-multiselect", () => import("./OMMultiSelect.vue"));
    vue.component("om-phoneinput", () => import("./OMPhoneInput.vue"));
    vue.component("om-pill", () => import("./OMPill.vue"));
    vue.component("om-radiobutton", () => import("./OMRadioButton.vue"));
    vue.component("om-textinput", () => import("./OMTextInput.vue"));
    vue.component("om-validation-error", () => import("./OMValidationError.vue"));
    vue.component("om-tabs", () => import("./Tabs/OMTabs.vue"));
    vue.component("om-tab", () => import("./Tabs/OMTab.vue"));
    vue.component("om-pivotgrid", () => import("./OMPivotGrid.vue"));
    vue.component("om-searchinput", () => import("./SearchFilter/OMSearchInput.vue"));
    vue.component("om-basicfilter", () => import("./SearchFilter/OMBasicFilterTag.vue"));
    vue.component("om-inputfilter", () => import("./SearchFilter/OMInputFilterTag.vue"));
    vue.component("om-treeview", () => import("./OMTreeView.vue"));
    vue.component("om-indented-listview", () => import("./OMIndentedListView.vue"));
    vue.component("om-spinner-block", () => import("./OMSpinnerBlock.vue"));
    vue.component("om-pulse-loader", () => import("./OMPulseLoader.vue"));
    vue.component("om-named-label", () => import("./OMNamedLabel.vue"));
    vue.component("om-bottom-line", () => import("./OMBottomLine.vue"));
    vue.component("om-skeleton", () => import("./Skeleton/OMSkeleton.vue"));
    vue.component("om-highlighted-label", () => import("./OMHighlightedLabel.vue"));
    vue.component("om-searchable-dropdown", () => import("./OMSearchableDropDown.vue"));
    vue.component("om-numeric-input", () => import("./OMNumericInput.vue"));
    vue.component("om-information-window", () => import("./OMInformationWindow.vue"));
    vue.component("om-video-player", () => import("./dialogs/VideoPlayerModal.vue"));
    vue.component("om-header", () => import("./OMHeader.vue"));
    vue.component("om-datepicker", () => import("./OMDatePicker.vue"));
    vue.component("om-calendar", () => import("./OMCalendar.vue"));
    vue.component("om-popover", () => import("./OMPopover.vue"));
    vue.component("om-textarea", () => import("./OMTextArea.vue"));
    vue.component("om-status", () => import("./OMStatus.vue"));
    vue.component("om-fileupload", () => import("./OMFileUpload.vue"));
    vue.component("om-search-textinput", () => import("./OMSearchTextInput.vue"));
    vue.component("om-textinput-async", () => import("./OMTextInputAsync.vue"))
  },
};
